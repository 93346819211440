import {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import {
  Avatar,
  Button,
  ChatSpeechRight,
  Dropdown,
  IconButton,
  Item,
  NavItem,
  TooltipRadix,
  TypographyPoppins,
  User,
} from '@visualist/design-system/src/components/v2';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { useAppData } from '@src/AppContext';
import { useUserOnboarding } from '@src/widgets/onboarding/useUserOnboarding';

import { $isExpanded, $showMobileSidebar, toggleMobileSidebar } from './model';
import { Navigation } from './ui/mobile-nav.tsx';

import styles from './styles.module.css';

export const Sidebar = forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children }, ref) => {
    const [showMobileSidebar] = useUnit([$showMobileSidebar]);

    const { width } = useWindowSize();

    const isMobile = width < 487;

    if (isMobile) {
      return (
        <>
          <Navigation />
          <div
            ref={ref}
            data-show-mobile-sidebar={showMobileSidebar}
            className={styles.mobileSidebar}
            style={
              {
                '--mobile-width': '298px',
              } as React.CSSProperties
            }
          >
            {children}
          </div>
          <div
            style={{
              display: showMobileSidebar ? 'block' : 'none',
            }}
            onClick={() => toggleMobileSidebar()}
            className={styles.invisibleBackdrop}
          ></div>
        </>
      );
    }

    return (
      <div ref={ref} className={styles.sidebar}>
        <div className={styles.sidebarActual}>{children}</div>
      </div>
    );
  },
);

export const SidebarHeader = ({ onClick }: { onClick: () => void }) => {
  const [isExpanded] = useUnit([$isExpanded]);

  return (
    <div className={styles.header} data-is-expanded={isExpanded}>
      {isExpanded ? (
        <Link to="/home">
          <Icon name="sprite/v-logo" className={styles.logo} size={24} />
        </Link>
      ) : null}
      <button className={styles.expandButton} onClick={onClick}>
        <Icon name="sprite/side-menu" size={20} />
      </button>
    </div>
  );
};

/**
 * User
 */

export const SidebarUser = () => {
  const { user, signOut } = useAppData();
  const [isExpanded] = useUnit([$isExpanded]);
  const history = useHistory();
  const { pathname } = useLocation();
  const isSelected = pathname.includes('profile');

  const logout = () => {
    signOut && signOut();
  };

  const goToAccount = () => {
    history.push('/account/profile');
  };

  const menuItems: Item<Variant>[] = [
    {
      leadingIcon: <User fill="none" />,
      content: 'Account settings',
      onClick: () => goToAccount(),
    },
    {
      leadingIcon: <Icon name="sprite/logout" />,
      content: 'Log out',
      onClick: () => logout(),
    },
  ];

  return (
    <div
      className={cn(styles.row, {
        [styles.selected]: isSelected,
      })}
    >
      <div className={styles.userSection}>
        <Dropdown>
          <Dropdown.Menu
            trigger={
              <TooltipRadix
                key={user.id}
                type="rich"
                title={
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      marginBottom: '12px',
                    }}
                  >
                    <Avatar
                      key={user.id}
                      image={user?.photo?.full_size}
                      initials={`${user.first_name ?? ''} ${
                        user.last_name ?? ''
                      }`}
                      size={40}
                    />
                    {user ? `${user.first_name} ${user.last_name}` : undefined}
                  </span>
                }
                description={
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '16px',
                      marginBottom: '8px',
                    }}
                  >
                    <Icon
                      name="sprite/at-sign"
                      style={{ paddingLeft: '8px' }}
                      color="var(--color-neutral-variant-30)"
                    />
                    {user.email}
                  </span>
                }
                descriptionSize="S"
                side="bottom"
              >
                <div className={styles.userRow}>
                  <div className={styles.user}>
                    <Avatar
                      size={24}
                      initials={`${user.first_name ?? ''} ${
                        user.last_name ?? ''
                      }`}
                      email={user.email}
                      image={user.photo?.medium_square_crop}
                    />
                  </div>
                  {isExpanded ? (
                    <TypographyPoppins
                      type="label"
                      size="L"
                      className={styles.userName}
                    >
                      {user.first_name ?? ''} {user.last_name ?? ''}
                    </TypographyPoppins>
                  ) : null}
                </div>
              </TooltipRadix>
            }
            side="top"
            collisionPadding={20}
            density="-2"
          >
            {menuItems.map((item, index) => (
              <Dropdown.MenuItem key={index} item={item} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export const SidebarNavItems = ({ children }: PropsWithChildren) => {
  const [isExpanded] = useUnit([$isExpanded]);
  return (
    <div data-expanded={isExpanded} className={styles.navItems}>
      {children}
    </div>
  );
};

export const SidebarItem = ({
  name,
  leftIcon,
  alternativeSelected = false,
  action,
  isSelected,
  isNested = false,
}: {
  name: string;
  leftIcon: ReactNode;
  alternativeSelected?: boolean;
  action:
    | {
        type: 'click';
        onClick: () => void;
      }
    | {
        type: 'link';
        to: `/${string}`;
      };
  isNested?: boolean;
  isSelected: boolean;
}) => {
  const [isExpanded] = useUnit([$isExpanded]);

  if (action.type === 'click') {
    const NavItemClick = (
      <NavItem
        minimal={!isExpanded}
        isSelected={isSelected}
        name={name}
        to=""
        onClick={action.onClick}
        leftIcon={leftIcon}
        isNested={isNested}
      />
    );

    if (isExpanded) {
      return NavItemClick;
    } else {
      return (
        <TooltipRadix description={name} side="right">
          {NavItemClick}
        </TooltipRadix>
      );
    }
  } else {
    const NavItemLink = (
      <NavItem
        minimal={!isExpanded}
        isSelected={isSelected || alternativeSelected}
        name={name}
        to={action.to}
        leftIcon={leftIcon}
        isNested={isNested}
      />
    );

    if (isExpanded) {
      return NavItemLink;
    } else {
      return (
        <TooltipRadix description={name} side="right">
          {NavItemLink}
        </TooltipRadix>
      );
    }
  }
};

export const SidebarCollapsibleItem = ({
  name,
  children,
  leftIcon,
  isNested = false,
  expandSidebar,
}: PropsWithChildren<{
  name: string;
  leftIcon: ReactNode;
  isNested?: boolean;
  expandSidebar: () => void;
}>) => {
  const [isSidebarExpanded] = useUnit([$isExpanded]);
  const [isExpanded, setIsExpanded] = useState(false);

  // Sync effector state to local state
  useEffect(() => {
    if (!isSidebarExpanded) setIsExpanded(false);
  }, [isSidebarExpanded]);

  if (!isSidebarExpanded) {
    return (
      <TooltipRadix description={name} side="right">
        <div className={styles.collapsibleNavItem}>
          <NavItem
            minimal={!isSidebarExpanded}
            onClick={() => {
              if (!isExpanded) {
                expandSidebar();
              }
              setIsExpanded((s) => !s);
            }}
            isSelected={false}
            leftIcon={leftIcon}
            to=""
            name={name}
            isNested={isNested}
            rightIcon={
              isExpanded ? (
                <Icon size={24} name="sprite/chevron-up" />
              ) : (
                <Icon size={24} name="sprite/chevron-down" />
              )
            }
          />
          {isExpanded ? (
            <div className={styles.collapsibleNavItemChildren}>{children}</div>
          ) : null}
        </div>
      </TooltipRadix>
    );
  }

  return (
    <div className={styles.collapsibleNavItem}>
      <NavItem
        minimal={!isSidebarExpanded}
        onClick={() => {
          if (!isExpanded) {
            expandSidebar();
          }
          setIsExpanded((s) => !s);
        }}
        isSelected={false}
        leftIcon={leftIcon}
        to=""
        name={name}
        isNested={isNested}
        rightIcon={
          isExpanded ? (
            <Icon size={24} name="sprite/chevron-up" />
          ) : (
            <Icon size={24} name="sprite/chevron-down" />
          )
        }
      />
      {isExpanded ? (
        <div className={styles.collapsibleNavItemChildren}>{children}</div>
      ) : null}
    </div>
  );
};

export const SidebarFooter = () => {
  const [isExpanded] = useUnit([$isExpanded]);

  const { startDemoMode } = useUserOnboarding();

  const gotoDemo = () => startDemoMode({});

  const openHelpCenter = () => {
    window.open('https://visualistapp.com/help-center/all', '_blank');
  };

  const openContactUs = () => {
    window.open('https://visualistapp.com/contact', '_blank');
  };

  const helpTrigger = isExpanded ? (
    <Button
      type="ghost"
      onClick={() => {}}
      label="Help"
      icon={
        <Icon
          name="sprite/question-in-circle"
          color="var(--color-secondary-40)"
        />
      }
      style={{
        color: 'var(--color-secondary-40)',
      }}
    />
  ) : (
    <TooltipRadix description="Help" side="right">
      <IconButton
        type="unfilled"
        onClick={() => {}}
        icon={<Icon name="sprite/question-in-circle" />}
      />
    </TooltipRadix>
  );

  const help = (
    <Dropdown>
      <Dropdown.Menu
        trigger={helpTrigger}
        side="top"
        sideOffset={15}
        collisionPadding={20}
        density="-2"
      >
        <Dropdown.MenuItem
          item={{
            leadingIcon: <Icon name="sprite/demo" />,
            content: 'Go to demo',
            onClick: gotoDemo,
          }}
        />
        <Dropdown.MenuItem
          item={{
            leadingIcon: <Icon name="sprite/FAQ" />,
            onClick: openHelpCenter,
            content: 'Help center',
          }}
        />
        <Dropdown.MenuItem
          item={{
            leadingIcon: <ChatSpeechRight fill="none" />,
            content: 'Contact us',
            onClick: openContactUs,
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );

  if (!isExpanded) {
    return (
      <div className={cn(styles.footer, styles.footerShrunk)}>
        {/* // <IconButton
        //   type="unfilled"
        //   onClick={() => {}}
        //   icon={<Icon name="sprite/team-soft-colored" />}
        // /> */}
        {help}
      </div>
    );
  }

  return (
    <div className={cn(styles.footer, styles.footerExpanded)}>
      {/* <Button
        type="ghost"
        onClick={() => {}}
        label="Invite"
        icon={<Icon name="sprite/team-24" color="var(--color-secondary-40)" />}
        style={{
          color: 'var(--color-secondary-40)',
        }}
      /> */}
      {help}
    </div>
  );
};

export const SidebarSpacer = ({ height }: { height?: number }) => {
  return (
    <div
      className={styles.spacer}
      style={
        {
          '--spacer-height': height ? `${height}px` : undefined,
        } as React.CSSProperties
      }
    ></div>
  );
};

export const SidebarDivider = () => {
  return <div className={styles.divider}></div>;
};
