import { AxiosResponse } from 'axios';

import {
  createTextbox,
  deleteTextbox,
  getTextBoxes,
  NewTextBoxData,
  TempTextBox,
  TextBox,
  TextBoxResponse,
  updateTextBox,
} from '@pages/StudioPage/api';
import { queryClient } from '@src/queryClient';
import { studioDesignKeys } from '@src/shared/constants/query-keys';
import { useMutation, useQuery } from '@tanstack/react-query';

type Props = {
  designId: string;
};

const FIVE_MINS_IN_MS = 1000 * 60 * 5;

export const useTextBoxes = ({ designId }: Props) => {
  const textBoxQuery = useQuery({
    queryKey: studioDesignKeys.text(designId),
    queryFn: () => getTextBoxes(designId),
    refetchOnReconnect: false,
    retry: false,
    staleTime: FIVE_MINS_IN_MS,
    select: (data) => data.data.results,
  });

  const createTextboxMutation = useMutation({
    mutationFn: (data: NewTextBoxData & { id: string }) => createTextbox(data),
    onSuccess: (d, variables) => {
      const newId = d.data.id;

      const queryData = queryClient.getQueryData<
        AxiosResponse<TextBoxResponse>
      >(studioDesignKeys.text(designId));

      if (!queryData) {
        textBoxQuery.refetch();
        return;
      }

      const newResults = [...queryData.data.results];

      const index = newResults.findIndex((r) => r.id === variables.id);

      if (index !== -1) {
        newResults[index] = {
          ...newResults[index],
          id: newId,
          text: variables.text,
          text_format: {
            alignment: variables.alignment,
            colour: variables.colour,
            bold: variables.bold,
            italic: variables.italic,
            rotation: variables.rotation,
            underline: variables.underline,
            additionalProp1: variables.fontSize,
          },
          width: variables.width,
        };

        const newQueryData = {
          ...queryData,
          data: {
            ...queryData.data,
            results: newResults,
          },
        };

        queryClient.setQueryData<AxiosResponse<TextBoxResponse>>(
          studioDesignKeys.text(designId),
          newQueryData,
        );

        // debouncedSelectTextId({ id: newId });
        return;
      }

      textBoxQuery.refetch();
    },
  });

  // const textboxMutation = useMutation(updateTextBox);

  const textboxMutation = useMutation({
    mutationFn: updateTextBox,
    onSuccess: () => {
      textBoxQuery.refetch();
    },
  });

  const deleteTextboxMutation = useMutation({
    mutationFn: ({ ids }: { ids: string[] }) => {
      return Promise.allSettled(ids.map((id) => deleteTextbox(id)));
    },
    onSuccess: () => {
      textBoxQuery.refetch();
    },
    onError: () => {
      textBoxQuery.refetch();
    },
  });

  const addNewTextbox = ({
    id,
    layer,
    position_x,
    position_y,
    set_block,
    text,
    text_format,
    width,
  }: TempTextBox) => {
    const queryData = queryClient.getQueryData<AxiosResponse<TextBoxResponse>>(
      studioDesignKeys.text(designId),
    );

    if (!queryData) return;

    const newTextBox: TextBox = {
      id,
      layer,
      position_x,
      position_y,
      set_block,
      text,
      text_format,
      width,
      created_at: new Date(),
      created_by: {
        email: '',
        first_name: '',
        id: 0,
        last_name: '',
        photo: {
          full_size: '',
          medium_square_crop: '',
          small_square_crop: '',
          thumbnail: '',
        },
      },
      block: null,
    };

    queryClient.setQueryData<AxiosResponse<TextBoxResponse>>(
      studioDesignKeys.text(designId),
      {
        ...queryData,
        data: {
          ...queryData.data,
          results: [...queryData.data.results, newTextBox],
        },
      },
    );
  };

  const removeTextbox = (id: string) => {
    const queryData = queryClient.getQueryData<AxiosResponse<TextBoxResponse>>(
      studioDesignKeys.text(designId),
    );

    if (!queryData) return;

    const filteredResults = queryData.data.results.filter((r) => r.id !== id);

    queryClient.setQueryData<AxiosResponse<TextBoxResponse>>(
      studioDesignKeys.text(designId),
      {
        ...queryData,
        data: {
          ...queryData.data,
          results: filteredResults,
        },
      },
    );
  };

  return {
    textBoxQuery,
    textboxMutation,
    createTextboxMutation,
    deleteTextboxMutation,
    addNewTextbox,
    removeTextbox,
  };
};
