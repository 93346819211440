import React, { useMemo } from 'react';

import { useUnit } from 'effector-react';
import { Stage } from 'konva/lib/Stage';
import { isMobile } from 'react-device-detect';

import { HandIcon, MoveIcon } from '@visualist/design-system';
import {
  SegmentedButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { useImages } from '@pages/StudioPage/hooks/useImages';
import { useTextBoxes } from '@pages/StudioPage/hooks/useTextBoxes';
import {
  $currentTool,
  $showLibrary,
  $stageState,
  changedTool,
  openedShuffler,
  toggledLibrary,
  updatedStage,
} from '@pages/StudioPage/model';
import { calculateNewStagePosition } from '@pages/StudioPage/utils';

import { FileUpload } from '../FileUpload';

import styles from './styles.module.css';

const ZOOM_SCALE_BY = 0.1;

type ToolbarProps = {
  stage: Stage | null;
  designId: string;
};

export const Toolbar = ({ stage, designId }: ToolbarProps) => {
  const [stageState, currentTool, showLibrary] = useUnit([
    $stageState,
    $currentTool,
    $showLibrary,
  ]);

  const { imageQuery } = useImages({
    designId,
  });
  const { textBoxQuery } = useTextBoxes({ designId });

  const uploadRef = React.useRef<HTMLInputElement>(null);

  const zoomIn = () => {
    if (!stage) return;

    const { scale, x, y } = calculateNewStagePosition({
      origin: {
        x: stage.getSize().width / 2,
        y: stage.getSize().height / 2,
      },
      previousScale: stageState.scale,
      newScale: stageState.scale + ZOOM_SCALE_BY,
      x: stageState.x,
      y: stageState.y,
    });

    updatedStage({
      scale,
      x,
      y,
    });
  };

  const isAddingStickyDisabled = useMemo(
    () => isStageEmpty(stage),
    [stage, imageQuery.data, textBoxQuery.data],
  );

  const zoomOut = () => {
    if (!stage) return;

    const { scale, x, y } = calculateNewStagePosition({
      origin: {
        x: stage.getSize().width / 2,
        y: stage.getSize().height / 2,
      },
      previousScale: stageState.scale,
      newScale: stageState.scale - ZOOM_SCALE_BY,
      x: stageState.x,
      y: stageState.y,
    });

    if (scale <= 0.1) return;

    updatedStage({
      scale,
      x,
      y,
    });
  };

  const uploadFromDevice = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.studioToolbar}>
        <TooltipRadix description="Select">
          <SegmentedButton
            start
            buttonStyle={styles.button}
            icon={<MoveIcon height={24} width={24} />}
            onClick={() => changedTool('select')}
            isSelected={currentTool === 'select' && !showLibrary}
          />
        </TooltipRadix>
        <TooltipRadix description="Pan">
          <SegmentedButton
            buttonStyle={styles.button}
            icon={<HandIcon height={24} width={24} />}
            onClick={() => changedTool('move')}
            isSelected={currentTool === 'move' && !showLibrary}
          />
        </TooltipRadix>
        <TooltipRadix description="Files">
          <SegmentedButton
            buttonStyle={styles.button}
            icon={<Icon name="sprite/magnifier" />}
            onClick={() => {
              changedTool('select');
              toggledLibrary();
            }}
            isSelected={showLibrary}
          />
        </TooltipRadix>
        <TooltipRadix description="Text">
          <SegmentedButton
            buttonStyle={styles.button}
            icon={<Icon name="sprite/textbox" />}
            onClick={() => changedTool('add-text')}
            isSelected={currentTool === 'add-text' && !showLibrary}
          />
        </TooltipRadix>
        <TooltipRadix description="Upload">
          <SegmentedButton
            buttonStyle={styles.button}
            icon={<Icon name="sprite/upload" />}
            onClick={() => uploadFromDevice()}
          />
        </TooltipRadix>
        <TooltipRadix
          description={
            isAddingStickyDisabled
              ? 'Add your first image or text to leave sticky notes'
              : 'Sticky notes'
          }
        >
          <SegmentedButton
            buttonStyle={styles.button}
            icon={<Icon name="sprite/sticky-add" />}
            onClick={() => changedTool('sticky')}
            isDisabled={isAddingStickyDisabled}
            isSelected={currentTool === 'sticky' && !showLibrary}
          />
        </TooltipRadix>
        <div className={styles.divider}></div>
        <TooltipRadix description="Tidy up">
          <SegmentedButton
            buttonStyle={styles.button}
            icon={<Icon name="sprite/tidy-up" />}
            onClick={() => openedShuffler()}
            isSelected={false}
          />
        </TooltipRadix>
        <FileUpload stage={stage} ref={uploadRef} designId={designId} />
      </div>
      {!isMobile && (
        <div className={styles.undoZoomToolbar}>
          <SegmentedButton
            start
            buttonStyle={styles.button}
            icon={<Icon name="sprite/zoom-out" />}
            onClick={zoomOut}
          />

          <SegmentedButton
            end
            buttonStyle={styles.button}
            icon={<Icon name="sprite/zoom-in" />}
            onClick={zoomIn}
          />
        </div>
      )}
    </div>
  );
};

const isStageEmpty = (stage: Stage | null) => {
  if (!stage) return true;

  const images = stage.children.find((l) => l.attrs.name === 'Images');
  const text = stage.children.find((l) => l.attrs.name === 'Text');

  return images?.children.length === 0 && text?.children.length === 0;
};
