import {
  Check,
  Dropdown,
  Item,
} from '@visualist/design-system/src/components/v2';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { Icon } from '@visualist/icons';

import {
  editedSticky,
  selectedStickyToDelete,
} from '@src/entities/Stickies/model';
import useStickies, { EmptySticky } from '@src/entities/Stickies/useStickies';

import { StickyType } from '../types';

import styles from './styles.module.css';

type Type = 'thread';

export const StickyDropDown = (props: {
  sticky: StickyType | EmptySticky;
  blockId: string;
  showDropdown: boolean;
  setShowDropdown: (show: boolean) => void;
  type?: Type;
  isActionable: boolean;
  toggleActionable: () => void;
}) => {
  const { mutateSticky } = useStickies(props.blockId, null);

  const closeSticky = () => props.setShowDropdown(false);

  const deleteSticky = () => {
    selectedStickyToDelete(props.sticky.id);
    closeSticky();
  };

  const changeStickyColour = (colour: string) => {
    mutateSticky.mutate({
      stickyId: props.sticky.id,
      data: {
        ...props.sticky,
        background_hex: colour,
      },
    });
  };

  const menuItemsTop: Item<Variant>[] = [
    {
      leadingIcon: <Icon name="sprite/pen" />,
      content: 'Edit',
      onClick: () => editedSticky(props.sticky.id),
    },
    {
      leadingIcon: <Icon name="sprite/checkbox" />,
      content: props.isActionable ? "Don't mark for action" : 'Mark for action',
      onClick: props.toggleActionable,
    },
  ];

  const subMenuItems: Item<Variant>[] = [
    {
      leadingIcon: [
        <ColourOption
          key={0}
          colour="picardy"
          isSelected={props.sticky.background_hex === '#FFE2B5'}
          changeStickyColour={() => changeStickyColour('#FFE2B5')}
        />,
        <ColourOption
          key={1}
          colour="wolfe"
          isSelected={props.sticky.background_hex === '#E2C9EB'}
          changeStickyColour={() => changeStickyColour('#E2C9EB')}
        />,
        <ColourOption
          key={2}
          colour="gretna"
          isSelected={props.sticky.background_hex === '#B3D4C1'}
          changeStickyColour={() => changeStickyColour('#B3D4C1')}
        />,
      ],
      content: '',
      classNameleadingIcon: styles.stickyColor,
    },
  ];

  const menuItemsBottom: Item<Variant>[] = [
    {
      leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
      content: 'Delete',
      onClick: deleteSticky,
    },
  ];

  if (!props.showDropdown) return null;

  return (
    <Dropdown open={props.showDropdown} onOpenChange={props.setShowDropdown}>
      <Dropdown.Menu
        side="bottom"
        sideOffset={8}
        density="-2"
        align={props.type === 'thread' ? 'end' : 'start'}
        alignOffset={props.type === 'thread' ? -4 : 0}
      >
        {menuItemsTop.map((item, index) => (
          <Dropdown.MenuItem key={index} item={item} />
        ))}

        <Dropdown.SubMenu
          icon={<Icon name="sprite/color-drop" />}
          subTriggerByClick="Change colour"
          preventDefault
        >
          {subMenuItems.map((item, index) => (
            <Dropdown.SubMenuItem key={index} item={item} />
          ))}
        </Dropdown.SubMenu>
        {menuItemsBottom.map((item, index) => (
          <Dropdown.MenuItem key={index} item={item} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

type ColourOptionProps = {
  colour: keyof typeof colourToVarDict;
  isSelected: boolean;
  changeStickyColour: () => void;
};

const colourToVarDict = {
  wolfe: 'var(--color-wolfe-soft)',
  picardy: 'var(--color-picardy-soft)',
  gretna: 'var(--color-gretna-soft)',
};

const ColourOption = ({
  colour,
  isSelected,
  changeStickyColour,
}: ColourOptionProps) => {
  const hex = colourToVarDict[colour];
  return (
    <div
      style={{
        backgroundColor: hex,
      }}
      className={styles.colourOption}
      onClick={changeStickyColour}
    >
      {isSelected && (
        <Check height={12} width={12} color="var(--color-neutral-variant-30)" />
      )}
    </div>
  );
};
