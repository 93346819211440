import { Dispatch, ReactNode, SetStateAction } from 'react';

import {
  Checkbox,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { AllFileCopiesResponse } from '@api/blocks';
import { BoardLvl1, BoardLvl2, BoardLvl3 } from '@api/designs';
import { Breadcrumbs } from '@src/entities/breadcrumbs';

import { BoardIdsToCopy, BoardIdsToRemove } from '..';

import styles from './styles.module.css';

interface ListItemProps {
  name: string | ReactNode;
  icon?: ReactNode;
  hasHub?: boolean;
  level?: number;
  checkbox: boolean;
  allBoardsIds: Array<string>;
  boardId: string;
  blockId?: string;
  allFileCopies?: AllFileCopiesResponse;
  idsSelectedBoards: Array<string>;
  setIdsSelectedBoards: Dispatch<SetStateAction<Array<string>>>;
  boardIdsToRemove: BoardIdsToRemove;
  setBoardIdsToRemove: Dispatch<SetStateAction<BoardIdsToRemove>>;
  boardIdsToCopy: BoardIdsToCopy;
  setBoardIdsToCopy: Dispatch<SetStateAction<BoardIdsToCopy>>;
  board?: BoardLvl1 | BoardLvl2 | BoardLvl3 | null;
}

export const ListItem = ({
  name,
  icon,
  checkbox,
  boardId,
  blockId,
  allBoardsIds,
  allFileCopies,
  idsSelectedBoards,
  setIdsSelectedBoards,
  boardIdsToRemove,
  boardIdsToCopy,
  setBoardIdsToRemove,
  setBoardIdsToCopy,
  board,
}: // hasHub,
ListItemProps) => {
  // blockId is available only when the list displays only boards where in there is a copy of this file, when searching for boards blokId has to be searched using the filter below
  const blockIdWhenBoardsSearchActivated = allFileCopies?.boards.filter(
    (board) => board.board_id === boardId,
  )[0]?.block_id;

  return (
    <li className={styles.item}>
      <div className={styles.itemWithBreadcrumbs}>
        {board && <Breadcrumbs board={board} />}
        <div className={styles.content}>
          <div className={styles.icon}>{icon}</div>
          <TypographyPoppins type="body" size="L" className={styles.name}>
            {name}
          </TypographyPoppins>
        </div>
      </div>
      {checkbox && (
        <div className={styles.checkbox}>
          {idsSelectedBoards?.some((id) => id === boardId) ? (
            <TooltipRadix description="Remove from this board">
              <Checkbox
                className={styles.border}
                icon={
                  <Icon
                    name="sprite/checkbox-filled"
                    size={30}
                    className={styles.checkboxIcon}
                  />
                }
                isSelected={idsSelectedBoards?.some((id) => id === boardId)}
                onClick={() => {
                  if (idsSelectedBoards.some((id) => id === boardId)) {
                    setIdsSelectedBoards((prev) => {
                      return prev.filter((id) => id !== boardId);
                    });

                    if (blockId) {
                      setBoardIdsToRemove((prev) => ({
                        blocks: [...prev.blocks, blockId],
                        boards: [...prev.boards, boardId],
                      }));
                    } else if (blockIdWhenBoardsSearchActivated) {
                      setBoardIdsToRemove((prev) => ({
                        blocks: [
                          ...prev.blocks,
                          blockIdWhenBoardsSearchActivated,
                        ],
                        boards: [...prev.boards, boardId],
                      }));
                    }
                  }

                  if (boardIdsToCopy.boards.length > 0) {
                    setBoardIdsToCopy((prev) => ({
                      boards: prev.boards.filter((id) => id !== boardId),
                    }));
                    setIdsSelectedBoards((prev) => {
                      return prev.filter((id) => id !== boardId);
                    });
                  }
                }}
              />
            </TooltipRadix>
          ) : (
            <TooltipRadix description="Copy to this board">
              <Checkbox
                className={styles.border}
                icon={<Icon name="sprite/checkbox" size={30} />}
                isSelected={idsSelectedBoards?.some((id) => id === boardId)}
                onClick={() => {
                  if (
                    allBoardsIds?.some((id) => id === boardId) &&
                    boardIdsToRemove?.boards.some((id) => id === boardId)
                  ) {
                    if (blockId) {
                      setBoardIdsToRemove((prev) => ({
                        blocks: prev.blocks.filter((id) => id !== blockId),
                        boards: prev.boards.filter((id) => id !== boardId),
                      }));
                    } else {
                      setBoardIdsToRemove((prev) => ({
                        blocks: prev.blocks.filter(
                          (id) => id !== blockIdWhenBoardsSearchActivated,
                        ),
                        boards: prev.boards.filter((id) => id !== boardId),
                      }));
                    }

                    setIdsSelectedBoards((prev) => [...prev, boardId]);
                  } else {
                    setBoardIdsToCopy((prev) => ({
                      boards: [...prev.boards, boardId],
                    }));
                    setIdsSelectedBoards((prev) => [...prev, boardId]);
                  }
                }}
              />
            </TooltipRadix>
          )}
        </div>
      )}
    </li>
  );
};

export const EmptyListItem = ({ name }: Pick<ListItemProps, 'name'>) => (
  <li className={styles.item}>
    <div className={styles.itemWithBreadcrumbs}>
      <div className={styles.content}>
        <div className={styles.icon}></div>
        <TypographyPoppins type="body" size="L" className={styles.name}>
          {name}
        </TypographyPoppins>
      </div>
    </div>
  </li>
);
