import cn from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router';

import {
  IconButton,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import styles from './styles.module.css';

interface BoardProps {
  name: string;
  id: string;
  boardIdAddedCopy: string;
  blockId: string;
}

export const Board = ({ name, boardIdAddedCopy, id, blockId }: BoardProps) => {
  const history = useHistory();

  return (
    <li
      className={cn(styles.board, {
        [styles.boardMobile]: isMobileOnly,
      })}
    >
      <TooltipRadix
        description={
          boardIdAddedCopy === id
            ? "You're viewing the copy that's in this board"
            : '  Switch to see copy in this board'
        }
        side="left"
      >
        <IconButton
          type="unfilled"
          className={styles.iconButton}
          icon={<Icon name="sprite/board" className={styles.icon} />}
          isSelected={boardIdAddedCopy === id}
          onClick={() => history.replace(`/b/${id}/#/f/${blockId}`)}
        />
      </TooltipRadix>
      <TypographyPoppins type="label" size="XS" className={styles.name}>
        {name}
      </TypographyPoppins>
    </li>
  );
};

export const BoardSkeleton = () => {
  return <div className={styles.skeleton}></div>;
};
