import { AxiosResponse } from 'axios';

import { UserType } from '@visualist/design-system';

import { User } from '@src/AppContext';

import { api } from './services';

export type FormSignUp = {
  email: string;
  password1: string;
  password2: string;
  first_name: string;
  last_name: string;
  // invitation_code: string;
  // campaign_code: string;
  signup_source: string;
  promo_code?: string;
};

// Allow only a subset of the user profile to be saved
type UserProfileSub = Pick<
  UserType,
  | 'meta'
  | 'email'
  | 'first_name'
  | 'last_name'
  | 'active_since'
  | 'location'
  | 'creative_field'
  | 'creative_field_other'
  | 'team_size'
  | 'average_fee'
  | 'project_number'
  | 'use_case'
  | 'acquisition_channel'
  | 'acquisition_channel_other'
>;

export type SaveProfilePayload = {
  [key in keyof UserProfileSub]?: UserType[key];
};

export const saveProfile = async (payload: SaveProfilePayload) => {
  const { data } = await api.patch('/auth/user/', payload);
  return data;
};

export const saveOnboarding = async (payload: { meta: User['meta'] }) => {
  const { data } = await api.patch('/auth/user/', payload);
  return data;
};

export const checkDemoCreation = async (): Promise<
  AxiosResponse<{ demo_status: 0 | 1 | 2 }>
> => {
  return await api.get('/start-demo/', {
    baseURL: `${import.meta.env.VITE_API_BASE_URL}/demo`,
  });
};

export const resetOnboarding = async () => {
  return await api.get('/reset-demo/');
};

export const changePassword = async (payload: {
  old_password: string;
  new_password1: string;
  new_password2: string;
}) => {
  const { data } = await api.post('/auth/password/change/', payload);
  return data;
};

export const fetchLogin = async (payload: {
  email: string;
  password: string;
}) => {
  const { data } = await api.post('/auth/login/', payload);
  return data;
};

export interface FetchSignUpResponse {
  user: UserType;
  data: Record<string, unknown>;
}

export const fetchSignUp = (data: FormSignUp): Promise<FetchSignUpResponse> =>
  api.post('/auth/registration/', data);

export const fetchLogout = async () => {
  const { data } = await api.post('/auth/logout/');
  return data;
};

export const fetchSendEmail = (data: { email: string }) =>
  api.post('/auth/password/reset/', data);

export const updatePassword = async (payload: {
  token: string | null;
  uid: string | null;
  new_password1: string;
  new_password2: string;
}) => {
  const { data } = await api.post('/auth/password/reset/confirm/', payload);
  return data;
};

// Google Linking

/**
 * @description This function is used to set a new password for a google user who does not have a current password.
 *
 * @param payload
 * @returns
 */

export const setNewPasswordForGoogleUser = async (payload: {
  new_password1: string;
  new_password2: string;
}): Promise<unknown> => {
  const { data } = await api.post('/auth/password/set/', payload);
  return data;
};

/**
 * @description This function is used to unlink a google account from a user.
 *
 * @returns void
 */

export const unlinkGogleAccount = async (): Promise<void> => {
  const { data } = await api.post('/auth/unlink-google/');
  return data;
};

/**
 * @description This function is used to link a google account from a user.
 *
 * @param payload
 * @returns void
 */

export const linkGogleAccount = async (payload: {
  access_token: string;
}): Promise<void> => {
  const { data } = await api.post('/auth/link-google/', payload);
  return data;
};
