import { ReactNode } from 'react';

import {
  Avatar,
  Badge,
  FilterChip,
  Switch,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Member } from '@api/docs';
import { Member as MemberHub } from '@api/hubs';
import { PermissionsHub } from '@api/hubs';
import {
  renderUserInitials,
  renderUserNameOrEmail,
} from '@src/shared/utils/names';

import { Permissions } from '../../ui/permissions';

import styles from './styles.module.css';

type FilterChipsProps = {
  user: Member | MemberHub;
  isShowMenu: boolean;
  setShowMenu: React.Dispatch<boolean>;
  getPermissions?: (permission: any) => ReactNode;
};

type SwitchProps = {
  user: Member | MemberHub;
  on: boolean;
  invite?: () => void;
  remove?: () => void;
};

type UserProps = {
  user: Member | MemberHub;
  isShowMenu: boolean;
  setShowMenu: React.Dispatch<boolean>;
  changePermissions: (permissions: PermissionsHub[]) => void;
  invite?: () => void;
  remove?: () => void;
  on: boolean;
  permissions: PermissionsHub[];
  permissionsRef: React.RefObject<HTMLDivElement>;
  getPermissions?: (permission: any) => ReactNode;
};

export const User = ({
  user,
  isShowMenu,
  setShowMenu,
  changePermissions,
  invite,
  remove,
  on,
  permissions,
  permissionsRef,
  getPermissions,
}: UserProps) => {
  return (
    <li key={user.id} className={styles.user}>
      <div className={styles.nameOrEmail}>
        <TooltipRadix
          key={user.id}
          classNameTrigger={styles.tooltip}
          type="rich"
          title={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginBottom: '12px',
              }}
            >
              <Avatar
                key={user.id}
                image={user.user?.photo?.full_size}
                initials={`${user.user?.first_name ?? ''} ${
                  user.user?.last_name ?? ''
                }`}
                size={40}
              />
              {user.user
                ? `${user.user.first_name} ${user.user.last_name}`
                : undefined}
            </span>
          }
          description={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                marginBottom: '8px',
              }}
            >
              <Icon
                name="sprite/at-sign"
                style={{ paddingLeft: '8px' }}
                color="var(--color-neutral-variant-30)"
              />
              {user.email}
            </span>
          }
          descriptionSize="S"
          side="bottom"
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Avatar
              image={user.user?.photo?.full_size}
              initials={renderUserInitials({
                first_name: user.user ? user.user.first_name : null,
                last_name: user.user ? user.user.last_name : null,
                email: user.email,
              })}
              size={20}
            />
            <TypographyPoppins
              className={styles.nameTitle}
              type="body"
              size="S"
            >
              {renderUserNameOrEmail({
                first_name: user.user ? user.user.first_name : null,
                last_name: user.user ? user.user.last_name : null,
                email: user.email,
              })}
            </TypographyPoppins>
          </span>
        </TooltipRadix>
        {user.is_owner && <Badge style="text" text="Owner" />}
        {!user.is_owner && !user.user && user.permissions ? (
          <Badge style="text" text="Pending" />
        ) : null}
      </div>
      <div className={styles.actions}>
        <div ref={permissionsRef} className={styles.permissions}>
          <FilterChips
            user={user}
            isShowMenu={isShowMenu}
            setShowMenu={setShowMenu}
            getPermissions={getPermissions}
          />
          <Permissions
            isShowMenu={isShowMenu}
            setShowMenu={setShowMenu}
            permissions={permissions}
            changePermissions={changePermissions}
          />
        </div>
        <Switchs user={user} on={on} invite={invite} remove={remove} />
      </div>
    </li>
  );
};

const FilterChips = ({
  user,
  isShowMenu,
  setShowMenu,
  getPermissions,
}: FilterChipsProps) => {
  return (
    <>
      {/* Owner */}
      {user.is_owner && (
        <FilterChip
          className={styles.permission}
          leadingIcon={
            isShowMenu && (
              <Icon
                name="sprite/tick"
                size={16}
                color="var(--color-neutral-variant-30)"
              />
            )
          }
          trailingIcon={
            isShowMenu ? (
              <Icon name="sprite/caret-down" size={16} />
            ) : (
              <Icon name="sprite/caret-up" size={16} />
            )
          }
          type="label"
          size="S"
          isDisabled
          onClick={() => setShowMenu(!isShowMenu)}
        >
          {getPermissions && getPermissions(user)}
        </FilterChip>
      )}

      {/* Invited users */}
      {!user.is_owner && user.permissions && (
        <FilterChip
          className={styles.permission}
          leadingIcon={
            isShowMenu && (
              <Icon
                name="sprite/tick"
                size={16}
                color="var(--color-neutral-variant-30)"
              />
            )
          }
          trailingIcon={
            isShowMenu ? (
              <Icon name="sprite/caret-down" size={16} />
            ) : (
              <Icon name="sprite/caret-up" size={16} />
            )
          }
          type="label"
          size="S"
          isSelected={isShowMenu}
          onClick={() => setShowMenu(!isShowMenu)}
        >
          {getPermissions && getPermissions(user)}
        </FilterChip>
      )}

      {/* Existing users and guest seats */}
      {!user.permissions && (
        <FilterChip
          className={styles.permission}
          leadingIcon={
            isShowMenu && (
              <Icon
                name="sprite/tick"
                size={16}
                color="var(--color-neutral-variant-30)"
              />
            )
          }
          trailingIcon={
            isShowMenu ? (
              <Icon name="sprite/caret-down" size={16} />
            ) : (
              <Icon name="sprite/caret-up" size={16} />
            )
          }
          type="label"
          size="S"
          isSelected={isShowMenu}
          onClick={() => setShowMenu(!isShowMenu)}
        >
          Editor
        </FilterChip>
      )}
    </>
  );
};

const Switchs = ({ user, on, invite, remove }: SwitchProps) => {
  return (
    <>
      {/* Owner */}
      {user.is_owner && (
        <Switch onValueChange={() => {}} value={true} disable />
      )}

      {/* Invited users */}
      {!user.is_owner && user.permissions && (
        <TooltipRadix description="Remove">
          <Switch
            onValueChange={() => {
              if (remove) {
                remove();
              }
            }}
            value={on}
          />
        </TooltipRadix>
      )}

      {/* Existing users and guest seats */}
      {!user.permissions && (
        <TooltipRadix description="Invite">
          <Switch
            onValueChange={() => {
              if (invite) {
                invite();
              }
            }}
            value={on}
          />
        </TooltipRadix>
      )}
    </>
  );
};
