import React, { forwardRef, ReactNode } from 'react';

import cn from 'classnames';

import { TypographyPoppins } from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

type Props = {
  labelStyle?: string;
  buttonStyle?: string;
  start?: boolean;
  end?: boolean;
  icon?: ReactNode;
  label?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  stopPropagation?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
};

export const SegmentedButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      labelStyle,
      buttonStyle,
      start,
      end,
      icon,
      label,
      onClick,
      stopPropagation,
      isDisabled,
      isSelected,
    },
    ref,
  ) => {
    const onClickHandler = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      if (stopPropagation) {
        e.stopPropagation();
      }

      onClick(e);
    };

    return (
      <button
        ref={ref}
        className={cn(styles.button, buttonStyle, {
          [styles.withLabel]: label,
          [styles.start]: start && !end,
          [styles.end]: end && !start,
          [styles.selected]: isSelected,
          [styles.disabled]: isDisabled && !isSelected,
        })}
        onClick={onClickHandler}
        disabled={isDisabled && !isSelected}
      >
        {icon && (
          <span
            className={cn(styles.icon, {
              [styles.disabledIcon]: isDisabled && !isSelected,
            })}
          >
            {icon}
          </span>
        )}
        <TypographyPoppins type="label" size="L">
          <span
            className={cn(styles.label, labelStyle, {
              [styles.disabledLabel]: isDisabled && !isSelected,
            })}
          >
            {label}
          </span>
        </TypographyPoppins>
      </button>
    );
  },
);
