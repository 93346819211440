import cn from 'classnames';
import { useUnit } from 'effector-react';

import {
  Button,
  Modal,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  $existingThemePlaceholder,
  $isShowColorCodesDialog,
  $swatchId,
  colorCodesDialogClosed,
  swatchIdSelected,
} from '@pages/FileCard/color-themes/model/edit-theme';
import { isLightColor } from '@src/shared/constants/colours';

import { DialogContainer } from '../ui/dialog-container';
import { Title } from '../ui/title';

import styles from './styles.module.css';

export const ColorCodesDialog = () => {
  const isShowColorCodesDialog = useUnit($isShowColorCodesDialog);
  const existingThemePlaceholder = useUnit($existingThemePlaceholder);
  const swatchId = useUnit($swatchId);

  const copyCode = async (code: string) => {
    try {
      await navigator.clipboard.writeText(code);

      startedSnack({
        label: 'Copied code',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy code",
        action: {
          label: 'Try again',
          action: () => {
            copyCode(code);
          },
        },
        close: true,
      });
    }
  };

  return (
    <Modal
      showModal={isShowColorCodesDialog}
      handleClose={colorCodesDialogClosed}
    >
      <DialogContainer className={styles.container}>
        <Title className={styles.title} title="Color codes" />
        <TypographyPoppins type="body" size="M" className={styles.description}>
          Select swatch to see the codes.
        </TypographyPoppins>
        <ul className={styles.theme}>
          {existingThemePlaceholder[0].swatches
            .filter((swatch) => swatch.hex_color)
            .map((swatch) => (
              <li
                key={swatch.id}
                style={{ background: swatch.hex_color }}
                className={cn(styles.swatch, {
                  [styles.light]: isLightColor(swatch.rgb_color),
                  [styles.nonActive]: swatchId !== swatch.id,
                })}
                onClick={() => swatchIdSelected(swatch.id)}
              />
            ))}
        </ul>
        <div className={styles.colorCodes}>
          <div className={styles.labels}>
            <TypographyPoppins type="label" size="S" className={styles.label}>
              Hex
            </TypographyPoppins>
            <TypographyPoppins type="label" size="S" className={styles.label}>
              RGB
            </TypographyPoppins>
            <TypographyPoppins type="label" size="S" className={styles.label}>
              HSL
            </TypographyPoppins>
            <TypographyPoppins type="label" size="S" className={styles.label}>
              HSV
            </TypographyPoppins>
          </div>

          <div className={styles.codes}>
            <Button
              className={styles.code}
              type="ghost"
              label={existingThemePlaceholder[0].swatches
                .filter((swatch) => swatch.id === swatchId)[0]
                ?.hex_color.replace(/^#/, '')}
              fontType="label"
              size="S"
              onClick={() =>
                copyCode(
                  existingThemePlaceholder[0].swatches
                    .filter((swatch) => swatch.id === swatchId)[0]
                    ?.hex_color.replace(/^#/, ''),
                )
              }
            />
            <Button
              className={styles.code}
              type="ghost"
              label={existingThemePlaceholder[0].swatches
                .filter((swatch) => swatch.id === swatchId)[0]
                ?.rgb_color.replace(/,/g, ', ')}
              fontType="label"
              size="S"
              onClick={() =>
                copyCode(
                  existingThemePlaceholder[0].swatches
                    .filter((swatch) => swatch.id === swatchId)[0]
                    ?.rgb_color.replace(/,/g, ', '),
                )
              }
            />
            <Button
              className={styles.code}
              type="ghost"
              label={existingThemePlaceholder[0].swatches
                .filter((swatch) => swatch.id === swatchId)[0]
                ?.hsl_color.replace(/%/g, '')
                .replace(/,/g, ', ')}
              fontType="label"
              size="S"
              onClick={() =>
                copyCode(
                  existingThemePlaceholder[0].swatches
                    .filter((swatch) => swatch.id === swatchId)[0]
                    ?.hsl_color.replace(/%/g, '')
                    .replace(/,/g, ', '),
                )
              }
            />
            <Button
              className={styles.code}
              type="ghost"
              label={existingThemePlaceholder[0].swatches
                .filter((swatch) => swatch.id === swatchId)[0]
                ?.hsv_color.replace(/%/g, '')
                .replace(/,/g, ', ')}
              fontType="label"
              size="S"
              onClick={() =>
                copyCode(
                  existingThemePlaceholder[0].swatches
                    .filter((swatch) => swatch.id === swatchId)[0]
                    ?.hsv_color.replace(/%/g, '')
                    .replace(/,/g, ', '),
                )
              }
            />
          </div>
        </div>
        <div className={styles.action}>
          <Button
            type="filled"
            label="Done"
            onClick={() => colorCodesDialogClosed()}
          />
        </div>
      </DialogContainer>
    </Modal>
  );
};
