import { getInvitees } from '@api/boards';
import { MEMBERS_INVITATION_QUERY } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

type Props = {
  id: string | null;
};

export const useInvitees = ({ id }: Props) => {
  const inviteesQuery = useQuery({
    queryKey: [MEMBERS_INVITATION_QUERY, id],
    queryFn: () => getInvitees(id),
    enabled: Boolean(id),
  });

  return {
    inviteesQuery,
  };
};
