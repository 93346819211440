import {
  createContext,
  Dispatch,
  forwardRef,
  MouseEventHandler,
  ReactNode,
  useContext,
  useState,
} from 'react';

import cn from 'classnames';

import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';

import {
  Size,
  TypographyPoppins,
  Variant,
} from '../Styles/Typography/TypographyPoppins';
import { TooltipRadix } from '../tooltip-radix';

import styles from './styles.module.css';

type Density = '0' | '-2';

type Side = 'left' | 'right' | 'bottom' | 'top';

export type Item<T extends Variant> = {
  leadingIcon?: ReactNode;
  content: string;
  supportingText?: string;
  trailingIcon?: ReactNode;
  isDisabled?: boolean;
  isDivider?: boolean;
  tooltipDescription?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  type?: T;
  size?: Size[T];
  classNameContent?: string;
  classNameleadingIcon?: string;
};

export const Dropdown = ({
  open,
  onOpenChange,
  children,
  modal,
}: {
  open?: boolean;
  onOpenChange?: Dispatch<boolean> | (() => void);
  children: ReactNode;
  modal?: boolean;
}) => {
  return (
    <RadixDropdownMenu.Root
      open={open}
      onOpenChange={onOpenChange}
      modal={modal}
    >
      {children}
    </RadixDropdownMenu.Root>
  );
};

const DropdownMenuContext = createContext({ density: '0' });

Dropdown.Menu = forwardRef<
  HTMLDivElement,
  {
    trigger?: ReactNode;
    side: Side;
    density?: Density;
    sideOffset?: number;
    collisionPadding?: number;
    alignOffset?: number;
    align?: 'start' | 'center' | 'end';
    className?: string;
    children: ReactNode;
    asChild?: boolean;
  }
>(
  (
    {
      trigger,
      side,
      density = '0',
      sideOffset = 5,
      collisionPadding = 0,
      alignOffset = 0,
      align = 'center',
      className,
      children,
      asChild = false,
    },
    ref,
  ) => {
    return (
      <DropdownMenuContext.Provider value={{ density }}>
        <RadixDropdownMenu.Trigger className={styles.trigger} asChild={asChild}>
          {trigger}
        </RadixDropdownMenu.Trigger>
        <RadixDropdownMenu.Portal>
          <RadixDropdownMenu.Content
            ref={ref}
            className={cn(styles.menu, className)}
            sideOffset={sideOffset}
            side={side}
            collisionPadding={collisionPadding}
            align={align}
            alignOffset={alignOffset}
          >
            {children}
          </RadixDropdownMenu.Content>
        </RadixDropdownMenu.Portal>
      </DropdownMenuContext.Provider>
    );
  },
);

Dropdown.MenuItem = forwardRef<
  HTMLDivElement,
  {
    item: Item<Variant>;
    textStyle?: React.CSSProperties;
  } & React.ComponentPropsWithoutRef<typeof RadixDropdownMenu.Item>
>(({ item, textStyle, ...rest }, ref) => {
  const { density } = useContext(DropdownMenuContext);

  return (
    <>
      {item.tooltipDescription ? (
        <>
          <TooltipRadix description={item.tooltipDescription}>
            <RadixDropdownMenu.Item
              {...rest}
              ref={ref}
              className={cn(styles.item, {
                [styles.disabled]: item.isDisabled,
                [styles.density]: density === '-2',
              })}
              data-item={item.content}
              disabled={item.isDisabled || !item.onClick}
              onClick={(e) => {
                e.stopPropagation();
                if (!item.isDisabled && item.onClick) {
                  item.onClick(e);
                }
              }}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
              >
                {item.leadingIcon && (
                  <span
                    className={cn(
                      styles.leadingIcon,
                      item.classNameleadingIcon,
                      {
                        [styles.density]: density === '-2',
                      },
                    )}
                  >
                    {item.leadingIcon}
                  </span>
                )}
                <div
                  className={cn(styles.content, {
                    [styles.withSupportingText]: item.supportingText,
                  })}
                >
                  <TypographyPoppins
                    className={cn(styles.text, item.classNameContent)}
                    type={item.type ? item.type : 'body'}
                    size={item.size ? item.size : 'L'}
                    style={textStyle}
                  >
                    {item.content}
                  </TypographyPoppins>

                  {item.supportingText && (
                    <TypographyPoppins
                      className={styles.supportingText}
                      type="body"
                      size="M"
                    >
                      {item.supportingText}
                    </TypographyPoppins>
                  )}
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {item.trailingIcon && (
                  <span
                    className={cn(styles.trailingIcon, {
                      [styles.density]: density === '-2',
                    })}
                  >
                    {item.trailingIcon}
                  </span>
                )}
              </div>
            </RadixDropdownMenu.Item>
          </TooltipRadix>
          {item.isDivider && (
            <RadixDropdownMenu.Separator className={styles.divider} />
          )}
        </>
      ) : (
        <>
          <RadixDropdownMenu.Item
            {...rest}
            className={cn(styles.item, {
              [styles.disabled]: item.isDisabled,
              [styles.density]: density === '-2',
            })}
            data-item={item.content}
            disabled={item.isDisabled || !item.onClick}
            onClick={(e) => {
              e.stopPropagation();
              if (!item.isDisabled && item.onClick) {
                item.onClick(e);
              }
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {item.leadingIcon && (
                <span
                  className={cn(styles.leadingIcon, item.classNameleadingIcon, {
                    [styles.density]: density === '-2',
                  })}
                >
                  {item.leadingIcon}
                </span>
              )}

              <div
                className={cn(styles.content, {
                  [styles.withSupportingText]: item.supportingText,
                })}
              >
                <TypographyPoppins
                  className={cn(styles.text, item.classNameContent, {
                    [styles.textDisabled]: item.isDisabled,
                  })}
                  type={item.type ? item.type : 'body'}
                  size={item.size ? item.size : 'L'}
                  style={textStyle}
                >
                  {item.content}
                </TypographyPoppins>

                {item.supportingText && (
                  <TypographyPoppins
                    className={styles.supportingText}
                    type="body"
                    size="M"
                  >
                    {item.supportingText}
                  </TypographyPoppins>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {item.trailingIcon && (
                <span
                  className={cn(styles.trailingIcon, {
                    [styles.density]: density === '-2',
                  })}
                >
                  {item.trailingIcon}
                </span>
              )}
            </div>
          </RadixDropdownMenu.Item>

          {item.isDivider && (
            <RadixDropdownMenu.Separator className={styles.divider} />
          )}
        </>
      )}
    </>
  );
});

const DropdownSubMenuContext = createContext({ preventDefault: false });

Dropdown.SubMenu = ({
  icon,
  sideOffset = 2,
  alignOffset = 0,
  subTriggerByClick,
  preventDefault,
  children,
}: {
  icon?: ReactNode;
  sideOffset?: number;
  alignOffset?: number;
  subTriggerByClick?: any;
  preventDefault?: boolean;
  children: ReactNode;
}) => {
  const { density } = useContext(DropdownMenuContext);
  const [open, setOpen] = useState(false);

  return (
    //@ts-ignore
    <DropdownSubMenuContext.Provider value={{ preventDefault }}>
      <RadixDropdownMenu.Sub open={subTriggerByClick ? open : undefined}>
        <RadixDropdownMenu.SubTrigger
          className={cn(styles.item, {
            [styles.density]: density === '-2',
          })}
          onClick={() => setOpen(!open)}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            {icon && (
              <span
                className={cn(styles.leadingIcon, {
                  [styles.density]: density === '-2',
                })}
              >
                {icon}
              </span>
            )}
            <TypographyPoppins className={styles.text} type="body" size="L">
              {subTriggerByClick}
            </TypographyPoppins>
          </div>
        </RadixDropdownMenu.SubTrigger>
        <RadixDropdownMenu.Portal>
          <RadixDropdownMenu.SubContent
            className={styles.subMenu}
            sideOffset={sideOffset}
            alignOffset={alignOffset}
          >
            {children}
          </RadixDropdownMenu.SubContent>
        </RadixDropdownMenu.Portal>
      </RadixDropdownMenu.Sub>
    </DropdownSubMenuContext.Provider>
  );
};

Dropdown.SubMenuItem = ({ item }: { item: Item<Variant> }) => {
  const { density } = useContext(DropdownMenuContext);
  const { preventDefault } = useContext(DropdownSubMenuContext);

  return (
    <RadixDropdownMenu.Item
      className={cn(styles.item, {
        [styles.disabled]: item.isDisabled,
        [styles.density]: density === '-2',
      })}
      disabled={item.isDisabled}
      onClick={(e) => {
        preventDefault && e.preventDefault();
        e.stopPropagation();
        if (!item.isDisabled && item.onClick) {
          item.onClick(e);
        }
      }}
    >
      {item.leadingIcon && (
        <span
          className={cn(styles.leadingIcon, item.classNameleadingIcon, {
            [styles.density]: density === '-2',
          })}
        >
          {item.leadingIcon}
        </span>
      )}
      <div
        className={cn(styles.content, {
          [styles.withSupportingText]: item.supportingText,
        })}
      >
        <TypographyPoppins
          className={cn(styles.text, item.classNameContent)}
          type={item.type ? item.type : 'body'}
          size={item.size ? item.size : 'L'}
        >
          {item.content}
        </TypographyPoppins>

        {item.supportingText && (
          <TypographyPoppins
            className={styles.supportingText}
            type="body"
            size="M"
          >
            {item.supportingText}
          </TypographyPoppins>
        )}
      </div>
      {item.trailingIcon && (
        <span
          className={cn(styles.trailingIcon, {
            [styles.density]: density === '-2',
          })}
        >
          {item.trailingIcon}
        </span>
      )}
    </RadixDropdownMenu.Item>
  );
};
