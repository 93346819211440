// import ReactDOM from 'react-dom';

import { createBrowserHistory } from 'history';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import ReactNotification from 'react-notifications-component';
import { Router } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { App } from './App';
import { AppDataProvider } from './AppContext';
import { queryClient } from './queryClient';
import { MaintenanceMode } from './widgets/maintenance-mode';

import '@fontsource/roboto/500.css';

const history = createBrowserHistory();
if (import.meta.env.PROD && import.meta.env.VITE_RENDER) {
  // Setup Sentry
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_IS_PULL_REQUEST
      ? 'preview'
      : import.meta.env.MODE,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.BrowserProfilingIntegration(),
    ],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    release: `${import.meta.env.VITE_RENDER_GIT_BRANCH}~${
      import.meta.env.VITE_RENDER_GIT_COMMIT
    }`,
  });

  // Setup Posthog
  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: 'https://eu.i.posthog.com',
    debug: true,
    bootstrap: {
      featureFlags: {
        'maintenance-mode': false,
      },
    },
    person_profiles: 'identified_only',
  });
}

const container = document.getElementById('root');

if (!container) {
  throw new Error('root element not found');
}

const root = createRoot(container);

root.render(
  <PostHogProvider client={posthog}>
    <MaintenanceMode>
      <QueryClientProvider client={queryClient}>
        <AppDataProvider>
          <Router history={history}>
            <App />
          </Router>
        </AppDataProvider>
        <ReactNotification />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </MaintenanceMode>
  </PostHogProvider>,
);
