import { isMobile } from 'react-device-detect';

import {
  IconButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { searchBoardsModalWindowOpened } from '../model';

import styles from './styles.module.css';

export const CopyFileOtherBoard = () => {
  return (
    <div className={styles.board}>
      <TooltipRadix
        description="Copy file to other boards"
        side={isMobile ? 'top' : 'left'}
      >
        <IconButton
          type="unfilled"
          className={styles.iconButton}
          icon={<Icon name="sprite/board" className={styles.icon} />}
          onClick={() => searchBoardsModalWindowOpened()}
        />
      </TooltipRadix>
    </div>
  );
};
