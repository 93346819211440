import { useState } from 'react';

import cn from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router';

import {
  Dropdown,
  IconButton,
  Item,
  StudioIcon,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { Icon } from '@visualist/icons';

import { getAllCopiesFile } from '@api/blocks';
import { useBlock } from '@pages/FileCard/model/queries/useBlock';
import { Source } from '@pages/FileCard/Source';
import { ALL_FILE_COPIES } from '@src/shared/constants/query-names';
import { useQuery } from '@tanstack/react-query';

import { Board } from '../../../../pages/FileCard/Board';
import { Close } from '../../../../pages/FileCard/Close';
import { Copy } from '../../../../pages/FileCard/Copy';
import { CopyFileOtherBoard } from '../../../../pages/FileCard/CopyFileOtherBoard';
import { Download } from '../../../../pages/FileCard/Download';

import styles from './styles.module.css';

// Mainly for when file card is open

export const NavRails = () => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const id = location.hash.replace('#/f/', '');

  const { data: block } = useBlock(id);

  const imageName = block?.file.full_size.split('/').slice(5).join('/');

  const {
    data: allFileCopies = {
      blocks: [],
      boards: [],
    },
    isFetchedAfterMount,
  } = useQuery({
    queryKey: [id, ALL_FILE_COPIES],
    queryFn: () => getAllCopiesFile(imageName),
    enabled: !!block?.id,
  });

  const getFileType = () => {
    if (block?.block_type === 'Image') {
      return 'image';
    } else if (block?.block_type === 'Set') {
      return 'design';
    } else {
      return 'palette';
    }
  };

  const goToStudio = async () => {
    history.push(`/studio/${block?.id}`);
  };

  const copyLink = async () => {
    const url = new URL(document.location.href);

    try {
      await navigator.clipboard.writeText(url.href);

      startedSnack({
        label: `Copied link to ${getFileType()}`,
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: `Couldn't copy link to ${getFileType()}`,
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const menuItems: Item<Variant>[] =
    block?.block_type === 'Set'
      ? [
          {
            leadingIcon: <StudioIcon fill="none" />,
            content: 'Edit in Studio',
            onClick: goToStudio,
          },
          {
            leadingIcon: <Icon name="sprite/link" size={21} />,
            content: 'Copy link',
            onClick: copyLink,
          },
        ]
      : [
          {
            leadingIcon: <Icon name="sprite/link" size={21} />,
            content: 'Copy link',
            onClick: copyLink,
          },
        ];

  return (
    <>
      <div className={styles.topRail}>
        <div className={styles.centerSide}>
          <Copy block={block} getFileType={getFileType} />
          <Download block={block} getFileType={getFileType} />
          <Source block={block} />
        </div>
        <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
          <Dropdown.Menu
            trigger={
              <IconButton
                type="unfilled"
                className={styles.iconButton}
                icon={<Icon name="sprite/3-dot-menu" />}
                onClick={() => {}}
                isSelected={isOpenDropdown}
              />
            }
            side="bottom"
            density="-2"
          >
            {menuItems.map((item) => (
              <Dropdown.MenuItem key={item.content} item={item} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Close />
      </div>
      <div className={styles.bottomRail}>
        <div className={styles.copyFileOtherBoard}>
          <CopyFileOtherBoard />
        </div>
        <ul
          className={cn(styles.boardsList, {
            [styles.boardsListMobile]: isMobileOnly,
          })}
        >
          {isFetchedAfterMount &&
            allFileCopies.boards?.length > 0 &&
            allFileCopies.boards.map((board) => (
              <Board
                key={board.board_id}
                id={board.board_id}
                name={board.board_name}
                blockId={board.block_id}
                boardIdAddedCopy={block?.boards?.[0]?.id ?? ''}
              />
            ))}
        </ul>
      </div>
    </>
  );
};
