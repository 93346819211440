import React from 'react';

import { useUnit } from 'effector-react';

import {
  SideSheet,
  TypographyItalic,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';

import { LookupKeyHubCredit } from '@api/billing';
import { useHubCredits } from '@pages/AccountPage/hooks/useHubCredits';
import {
  $isHubCreditSheetOpen,
  $selectedHubCreditBundleId,
  closedHubCreditSheet,
  CREDIT_BUNDLES,
  CreditBundle,
  CreditBundles,
} from '@pages/AccountPage/models';
import { filterOneCreditPack, sortHubBundles } from '@pages/AccountPage/utils';

import styles from './styles.module.css';

// TODO: clean up the names and long lines everywhere and check why hasCreditShortfall is not a sufficient type guard

export const HubCreditSheet = () => {
  const [isHubCreditSheetOpen, selectedHubCreditBundleId] = useUnit([
    $isHubCreditSheetOpen,
    $selectedHubCreditBundleId,
  ]);
  const [selectedBundleId, setSelectedBundleId] = React.useState<
    keyof typeof CREDIT_BUNDLES | undefined
  >(undefined);
  const { isMobile } = useWindowSize();

  const {
    hubProductsQuery: { data, isLoading, isSuccess },
    buyHubCredits,
  } = useHubCredits();

  const closeSheet = () => closedHubCreditSheet();

  React.useEffect(() => {
    // Synchronise state of selectedBundleId and selectedHubCreditBundleId
    // Get the initial value and store it
    if (selectedHubCreditBundleId && !selectedBundleId) {
      setSelectedBundleId(selectedHubCreditBundleId.bundleId);
    }
    if (!selectedHubCreditBundleId) setSelectedBundleId(undefined);
  }, [selectedHubCreditBundleId]);

  const hasCreditShortfall =
    selectedHubCreditBundleId !== null &&
    typeof selectedHubCreditBundleId.creditShortfallPurchaseAmount !==
      'undefined';

  const checkoutHubCreditBundle = () => {
    if (!selectedHubCreditBundleId) return;

    const selectedBundle = data?.find(
      (bundle) => bundle.lookup_key === selectedBundleId,
    );

    if (!selectedBundle) return;

    // if user has selected credit pack 1 use deficit quantity aka we want 10 of credit pack 1, otherwise for any other amount use 1 as as we want 1 of credit pack 100,250,500
    const use1CreditPack = selectedBundle.lookup_key === 'credit_pack_1';

    buyHubCredits.mutate({
      quantity: use1CreditPack
        ? selectedHubCreditBundleId.creditShortfallPurchaseAmount?.deficit || 1
        : 1,
      purchasePurpose: hasCreditShortfall ? 'hub_shortfall' : 'credit_pack',
      purchaseCharge: hasCreditShortfall
        ? selectedHubCreditBundleId.creditShortfallPurchaseAmount
            ?.totalAmount ?? 0
        : 0,
      stripePriceId: selectedBundle.stripe_price_id,
      purchaseName:
        selectedHubCreditBundleId.creditShortfallPurchaseAmount?.hubName,
      lookupKey: selectedBundle.lookup_key,
    });
  };

  const selectBundle = (bundleId: CreditBundle) => {
    setSelectedBundleId(bundleId);
  };

  if (isLoading) return <p>Loading</p>;

  if (isSuccess && data) {
    return (
      <SideSheet
        className={styles.sideSheetContainer}
        handleBackdropClick={closeSheet}
        showSheet={isHubCreditSheetOpen}
        closeSideSheet={closeSheet}
      >
        <SideSheet.Heading closeSideSheet={closeSheet}>
          Pre-pay and save
        </SideSheet.Heading>
        <SideSheet.Body>
          <SideSheet.ContentContainer>
            {isMobile ? (
              <TypographyPoppins
                type="title"
                size="L"
                className={styles.headingLabel}
              >
                Pre-pay and save
              </TypographyPoppins>
            ) : null}
            {hasCreditShortfall &&
            selectedHubCreditBundleId.creditShortfallPurchaseAmount ? (
              <TypographyPoppins
                type="body"
                size="M"
                className={styles.shortfallText}
              >
                You need{' '}
                {
                  selectedHubCreditBundleId.creditShortfallPurchaseAmount
                    ?.deficit
                }{' '}
                more{' '}
                {selectedHubCreditBundleId.creditShortfallPurchaseAmount
                  .deficit > 1
                  ? 'credits'
                  : 'credit'}
                .
                <br />
                <br />
                We pro-rate the charge for the number of days remaining in your
                cycle.
              </TypographyPoppins>
            ) : null}
            <div className={styles.radioContainer}>
              {
                // Add a Radio button for credit shortfall
                hasCreditShortfall &&
                selectedHubCreditBundleId.creditShortfallPurchaseAmount ? (
                  <RadioButtonForCreditShortfall
                    selectBundle={selectBundle}
                    key="1"
                    lookupKey="credit_pack_1"
                    stripePriceId={
                      data.find((h) => h.lookup_key === 'credit_pack_1')
                        ?.stripe_price_id || ''
                    }
                    selected={selectedBundleId === 'credit_pack_1'}
                    numberOfCredits={
                      selectedHubCreditBundleId.creditShortfallPurchaseAmount
                        .deficit
                    }
                    priceOfCredits={
                      selectedHubCreditBundleId.creditShortfallPurchaseAmount
                        .cost
                    }
                  />
                ) : null
              }
              {data
                .filter(filterOneCreditPack)
                .sort(sortHubBundles)
                .map((bundle) => {
                  return (
                    <RadioButton
                      key={bundle.id}
                      bundle={CREDIT_BUNDLES[bundle.lookup_key]}
                      lookupKey={bundle.lookup_key}
                      stripePriceId={bundle.stripe_price_id}
                      selected={bundle.lookup_key === selectedBundleId}
                      selectBundle={selectBundle}
                    />
                  );
                })}
            </div>
            <SideSheet.Content>
              <span className={styles.content}>
                {hasCreditShortfall
                  ? 'The credits will be added to your account and used immediately.'
                  : 'The credits will be added to your account immediately.'}
                <br />
                <br />
                This is a one-time charge and non-refundable.
                <br />
                <br />
                Credits never expire.
              </span>
            </SideSheet.Content>
          </SideSheet.ContentContainer>
        </SideSheet.Body>
        <SideSheet.BottomControls
          hideCancel
          isDisabled={buyHubCredits.isPending}
          primaryLabel={
            hasCreditShortfall ? 'Purchase and create' : 'Continue to checkout'
          }
          closeSideSheet={closeSheet}
          mainClick={checkoutHubCreditBundle}
        />
      </SideSheet>
    );
  }

  return null;
};

/**
 * Radio button for credit shortfall
 * @description
 * Special radio component to keep the normal one clean. This one has special uses for when a user has a credit shortfall.
 *
 * @param props
 * @constructor
 * @return {JSX.Element}
 * @example
 */

const RadioButtonForCreditShortfall = (props: {
  selected: boolean;
  stripePriceId: string;
  numberOfCredits: number;
  priceOfCredits: number;
  lookupKey: CreditBundle;
  selectBundle: (bundleId: CreditBundle) => void;
}) => {
  const onChange = () => props.selectBundle(props.lookupKey);

  return (
    <label className={styles.checkbox}>
      <input
        className={styles.input}
        type="radio"
        onChange={onChange}
        value={props.stripePriceId}
        checked={props.selected}
      />
      <div className={styles.labelContainer}>
        <div className={styles.headingContainer}>
          <TypographyPoppins type="body" size="L">
            <span className={styles.neutral10}>{props.numberOfCredits}</span>
          </TypographyPoppins>
          <TypographyPoppins type="body" size="S">
            <span className={styles.neutral10}>credits for</span>
          </TypographyPoppins>
          <TypographyPoppins type="body" size="L">
            <span className={styles.total}>${props.priceOfCredits / 100}</span>
          </TypographyPoppins>
        </div>
      </div>
    </label>
  );
};

type Props = {
  bundle: CreditBundles[CreditBundle];
  stripePriceId: string;
  selected: boolean;
  lookupKey: LookupKeyHubCredit;
  selectBundle: (bundleId: CreditBundle) => void;
};

const RadioButton = ({
  bundle,
  selected,
  stripePriceId,
  lookupKey,
  selectBundle,
}: Props) => {
  const onChange = () => selectBundle(lookupKey);

  return (
    <label className={styles.checkbox}>
      <input
        className={styles.input}
        type="radio"
        onChange={onChange}
        value={stripePriceId}
        checked={selected}
      />
      <div className={styles.labelContainer}>
        <div className={styles.headingContainer}>
          <TypographyPoppins type="body" size="L">
            <span className={styles.neutral10}>{bundle.numberOfCredits}</span>
          </TypographyPoppins>
          <TypographyPoppins type="body" size="S">
            <span className={styles.neutral10}>credits for</span>
          </TypographyPoppins>
          <TypographyPoppins type="body" size="S">
            <span className={styles.striked}>${bundle.originalTotal}</span>
          </TypographyPoppins>
          <TypographyPoppins type="body" size="L">
            <span className={styles.total}>${bundle.discountedTotal}</span>
          </TypographyPoppins>
        </div>
        <div className={styles.headingContainer}>
          <TypographyItalic formattingBody="small italic">
            <span className={styles.amountPerCredit}>
              ${bundle.amountPerCredit} per credit
            </span>
          </TypographyItalic>
          <TypographyItalic formattingBody="small italic">
            <span className={styles.total}>({bundle.discount}% off)</span>
          </TypographyItalic>
        </div>
      </div>
    </label>
  );
};
