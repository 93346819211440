import { forwardRef, ReactNode } from 'react';

import * as PopoverPrimitive from '@radix-ui/react-popover';

import styles from './styles.module.css';

type Props = {
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  align?: 'start' | 'center' | 'end';
  children: ReactNode;
};

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;

export const PopoverContent = forwardRef<HTMLDivElement, Props>(
  ({ children, sideOffset = 8, side, align }, forwardedRef) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={forwardedRef}
        className={styles.PopoverContent}
        side={side}
        sideOffset={sideOffset}
        align={align}
      >
        {children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  ),
);
