import { useUnit } from 'effector-react';

import {
  IconButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import {
  $isShowElementsPanel,
  $isShowFilesPanel,
  elementsPanelToggled,
  filesPanelToggled,
} from '../../../../pages/DocPage/model/sidebar-opening';

import styles from './styles.module.css';

export const NavPanel = () => {
  const isShowElementsPanel = useUnit($isShowElementsPanel);
  const isShowFilesPanel = useUnit($isShowFilesPanel);

  return (
    <ul className={styles.navPanel}>
      <li className={styles.item}>
        <IconButton
          className={styles.iconButton}
          type="unfilled"
          icon={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {isShowElementsPanel ? (
                <Icon name="sprite/elements-filled" className={styles.icon} />
              ) : (
                <Icon name="sprite/elements" className={styles.icon} />
              )}
            </div>
          }
          isSelected={isShowElementsPanel}
          onClick={() => elementsPanelToggled()}
        />
        <TypographyPoppins className={styles.label} type="label" size="M">
          Elements
        </TypographyPoppins>
      </li>
      <li className={styles.item}>
        <IconButton
          className={styles.iconButton}
          type="unfilled"
          icon={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {isShowFilesPanel ? (
                <Icon name="sprite/library-filled" className={styles.icon} />
              ) : (
                <Icon name="sprite/library" className={styles.icon} />
              )}
            </div>
          }
          isSelected={isShowFilesPanel}
          onClick={() => filesPanelToggled()}
        />
        <TypographyPoppins className={styles.label} type="label" size="M">
          Files
        </TypographyPoppins>
      </li>
    </ul>
  );
};
