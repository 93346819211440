import cn from 'classnames';
import { useUnit } from 'effector-react';

import {
  Button,
  FilterChip,
  Modal,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { getInvitees } from '@api/boards';
import { useAddBoardToHub } from '@pages/BoardPage/model/queries/useAddBoardToHub';
import { useRemoveBoardFromHub } from '@pages/BoardPage/model/queries/useRemoveBoardFromHub';
import { Title } from '@src/entities/dialogs/ui/title';
import { HubSearch } from '@src/entities/search-modal/hub-search';
import { MEMBERS_INVITATION_QUERY } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

import { DialogContainer } from '../../ui/dialog-container';
import { useSearch } from '../creation/search/useSearch';
import {
  $boardIdForHub,
  $hubdIdForAdding,
  $hubdIdForDeleting,
  $hubName,
  $isSearchModalOpen,
  $isShowMoveBoardDialog,
  hubIdForAddingCleared,
  hubIdForAddingSelected,
  hubNameCleared,
  hubNameEntered,
  moveBoardDialogClosed,
  searchModalClosed,
  searchModalOpened,
  warningDialogOpened,
} from './model';

import styles from './styles.module.css';

export const MoveBoardDialog = () => {
  const isShowMoveBoardDialog = useUnit($isShowMoveBoardDialog);
  const isSearchModalOpen = useUnit($isSearchModalOpen);
  const hubdIdForAdding = useUnit($hubdIdForAdding);
  const hubdIdForDeleting = useUnit($hubdIdForDeleting);
  const hubName = useUnit($hubName);
  const boardId = useUnit($boardIdForHub);

  const { ref, width } = useSearch();

  const { data: invitees } = useQuery({
    queryKey: [MEMBERS_INVITATION_QUERY, boardId],
    queryFn: () => {
      if (boardId) {
        return getInvitees(boardId);
      } else {
        return null;
      }
    },
  });

  const { addBoardToHub } = useAddBoardToHub();
  const { deleteBoardFromHub } = useRemoveBoardFromHub();

  const clear = () => {
    if (hubName) {
      hubIdForAddingCleared();
      hubNameCleared();
    }
  };

  const moveBoard = () => {
    if (hubdIdForAdding && boardId && hubdIdForAdding !== hubdIdForDeleting) {
      addBoardToHub({ hub: hubdIdForAdding, boards: [boardId] });
    } else if (
      hubdIdForDeleting &&
      boardId &&
      !hubdIdForAdding &&
      invitees &&
      invitees.members.length <= 1
    ) {
      deleteBoardFromHub({ hub: hubdIdForDeleting, boards: [boardId] });
    } else if (
      hubdIdForDeleting &&
      boardId &&
      !hubdIdForAdding &&
      invitees &&
      invitees.members.length > 1
    ) {
      warningDialogOpened();
      return;
    }

    moveBoardDialogClosed();
  };

  return (
    <Modal
      showModal={isShowMoveBoardDialog}
      handleClose={moveBoardDialogClosed}
    >
      <DialogContainer className={styles.container}>
        <Title
          className={styles.title}
          title="Move board"
          description="Choose which hub to put this board in. All sub-boards will also be moved."
        />
        <div className={styles.location}>
          <div className={styles.selection}>
            {hubName && (
              <TypographyPoppins className={styles.nest} type="body" size="S">
                Put board in
              </TypographyPoppins>
            )}

            <HubSearch
              onResultClick={(hub) => {
                hubIdForAddingSelected(hub.id);
                hubNameEntered(hub.name);
                searchModalClosed();
              }}
              selectedHub={{ id: hubdIdForAdding }}
              isOpen={isSearchModalOpen}
              setIsOpen={searchModalOpened}
            >
              <FilterChip
                ref={ref}
                className={cn({
                  [styles.filterChip]: hubName,
                })}
                classNameLabel={cn({
                  [styles.blur]: width >= 302,
                })}
                leadingIcon={hubName && <Icon name="sprite/hub" />}
                trailingIcon={
                  hubName ? (
                    <Icon
                      name="sprite/x"
                      color="var(--color-neutral-variant-30)"
                    />
                  ) : (
                    <Icon name="sprite/caret-down" />
                  )
                }
                trailingAction={hubName ? clear : undefined}
                isSelected={Boolean(hubName)}
              >
                {hubName ? hubName : 'Select hub'}
              </FilterChip>
            </HubSearch>
          </div>
          <TypographyPoppins className={styles.tip} type="body" size="S">
            {hubName
              ? 'You’ll be able to invite others to this board.'
              : 'If you want to invite others to this board, you’ll need to move it into a hub.'}
          </TypographyPoppins>
        </div>
        <div className={styles.actions}>
          <Button
            type="outlined"
            label="Cancel"
            onClick={() => moveBoardDialogClosed()}
          />
          <Button type="filled" label="Done" onClick={moveBoard} />
        </div>
      </DialogContainer>
    </Modal>
  );
};
