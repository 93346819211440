import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import useOnclickOutsideMultiple from 'react-cool-onclickoutside';

import {
  MenuItem,
  TextField,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { useHubs } from '@pages/HubPage/model/queries/useHubs';

import { useGoogleAutocomplete } from '../../shared/hooks/use-google-autocomplete';

import styles from './styles.module.css';

type Props = {
  hubId: string;
  location?: string;
};

export const Autocomplete = ({ hubId, location }: Props) => {
  const [input, setInput] = useState<string>('');
  const [isReadOnly, setReadOnly] = useState(true);

  const inputRef = useRef<HTMLInputElement>(null);

  const { getPlacePredictions, placePredictions } = useGoogleAutocomplete({
    input,
  });

  useEffect(() => {
    if (location) {
      setInput(location);
    } else {
      setInput('');
    }
  }, [location]);

  const itemsList = useOnclickOutsideMultiple(() => {
    getPlacePredictions({ input: '' });
  });

  const { editHub } = useHubs({});

  const onDoubleClick = () => {
    setReadOnly(false);

    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  const onBlur = () => {
    setReadOnly(true);

    if (input.length === 0) {
      editHub({
        location: input,
        hubId,
      });
    }
  };

  return (
    <div className={styles.location}>
      <div ref={itemsList} className={styles.searchField}>
        <Icon name="sprite/location-pin" className={styles.icon} />
        <TextField
          className={styles.textField}
          inputStyle={cn(styles.input, {
            [styles.readOnly]: isReadOnly,
          })}
          ref={inputRef}
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            getPlacePredictions({ input: e.target.value });
          }}
          placeholder={isReadOnly ? 'add location' : ''}
          readOnly={isReadOnly}
          onDoubleClick={onDoubleClick}
          onBlur={onBlur}
        />
      </div>

      <ul ref={itemsList} className={styles.itemsList}>
        {placePredictions.map(({ description, place_id }) => {
          return (
            <MenuItem
              key={place_id}
              className={styles.item}
              classNameContent={styles.content}
              leadingIcon={
                <Icon
                  name="sprite/location-pin-filled"
                  className={styles.icon}
                />
              }
              onClickContent={() => {
                setInput(description);
                getPlacePredictions({ input: '' });
                editHub({
                  location: description,
                  hubId,
                });
              }}
              density="-2"
            >
              {description}
            </MenuItem>
          );
        })}
      </ul>
    </div>
  );
};
