import { useUnit } from 'effector-react';
import { useHistory } from 'react-router';

import {
  CheckboxRound,
  Dropdown,
  FilterChip,
  IconButton,
  RadioButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { getHubs } from '@api/hubs';
import {
  $actionFilters,
  toggleStatusFilter,
  updatedDueFilter,
  updatedFilterByHub,
  updatedHubFilter,
  updatedOrderingFilter,
  updatedSortOrder,
} from '@pages/action-items/modal';
import { HubSearch } from '@src/entities/search-modal/hub-search';
import { ALL_HUBS_QUERY } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

import styles from './styles.module.css';

interface ActionItemsHeaderProps {
  createActionItem: () => void;
}
export const ActionItemsHeader = ({
  createActionItem,
}: ActionItemsHeaderProps) => {
  return (
    <div className={styles.actionItemHeaderContainer}>
      <ActionItemTitle createActionItem={createActionItem} />
      <ActionItemFilterChips />
      <ActionItemSort />
      <ActionItemFilter />
    </div>
  );
};

const ActionItemTitle = ({ createActionItem }: ActionItemsHeaderProps) => {
  const history = useHistory();
  return (
    <div className={styles.actionItemTitleWrapper}>
      <TypographyPoppins
        type="title"
        size="L"
        className={styles.actionItemTitle}
      >
        Action items
      </TypographyPoppins>
      <IconButton
        type="unfilled"
        icon={<Icon size={24} name="sprite/plus" />}
        onClick={() => {
          createActionItem();
          history.push(`/action-items/new`);
        }}
        className={styles.actionItemCreateBtn}
      />
    </div>
  );
};

const ActionItemFilterChips = () => {
  const [filters] = useUnit([$actionFilters]);

  return (
    <div className={styles.actionItemFilterChipsWrapper}>
      <FilterChip
        isSelected={filters.due === ''}
        onClick={() => updatedDueFilter('')}
      >
        All
      </FilterChip>
      <FilterChip
        isSelected={filters.due === 'today'}
        onClick={() => updatedDueFilter('today')}
      >
        Today
      </FilterChip>
      <FilterChip
        isSelected={filters.due === 'upcoming'}
        onClick={() => updatedDueFilter('upcoming')}
      >
        Upcoming
      </FilterChip>
      <FilterChip
        isSelected={filters.due === 'expired'}
        onClick={() => updatedDueFilter('expired')}
      >
        Overdue
      </FilterChip>
      <FilterChip
        onClick={() =>
          toggleStatusFilter(filters.status === 'open' ? '' : 'open')
        }
        isSelected={filters.status === ''}
        leadingIcon={
          <CheckboxRound stroke="var(--color-primary-40)" fill="none" />
        }
      >
        Show closed
      </FilterChip>
    </div>
  );
};

const ActionItemSort = () => {
  const [filters] = useUnit([$actionFilters]);

  return (
    <div className={styles.actionItemSortWrapper}>
      <TypographyPoppins
        type="body"
        size="M"
        className={styles.actionItemSortByTitle}
      >
        Sort by
      </TypographyPoppins>
      <Dropdown>
        <Dropdown.Menu
          side="bottom"
          density="-2"
          align="start"
          trigger={
            <FilterChip>
              {filters.ordering === 'created_at'
                ? 'Date created'
                : filters.ordering === 'priority'
                ? 'Priority'
                : filters.ordering === 'due_date'
                ? 'Due date'
                : 'Date created'}
            </FilterChip>
          }
        >
          <Dropdown.MenuItem
            item={{
              content: 'Date created',
              onClick: () => updatedOrderingFilter('created_at'),
            }}
          />
          <Dropdown.MenuItem
            item={{
              content: 'Priority',
              onClick: () => updatedOrderingFilter('priority'),
            }}
          />
          <Dropdown.MenuItem
            item={{
              content: 'Due date',
              onClick: () => updatedOrderingFilter('due_date'),
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
      <IconButton
        onClick={() => {
          filters.sortOrder === 'ascending'
            ? updatedSortOrder('descending')
            : updatedSortOrder('ascending');
        }}
        className={styles.sortBtn}
        type="unfilled"
        icon={
          <Icon
            name="sprite/arrow-down"
            style={{
              transform: `rotate(${
                filters.sortOrder === 'ascending' ? '180deg' : '0deg'
              })`,
            }}
          />
        }
      />
    </div>
  );
};

type FilterType = 'all' | 'hub';
const ActionItemFilter = () => {
  const [filters] = useUnit([$actionFilters]);

  const { data: hubs = [] } = useQuery({
    queryKey: [ALL_HUBS_QUERY],
    queryFn: () => getHubs({ ordering: '-created_at' }),
  });

  const handleFilterChange = (newFilter: FilterType) => {
    updatedFilterByHub(newFilter);
    // Current filter before change
    if (filters.filterByHub === 'hub') {
      updatedHubFilter(null);
    } else {
      // Set to initial hub data if no hub data is present
      if (hubs.length) {
        updatedHubFilter({
          id: hubs[0].id,
          name: hubs[0].name,
        });
      }
    }
  };

  return (
    <>
      <div className={styles.actionItemFilterWrapper}>
        <TypographyPoppins
          type="body"
          size="M"
          className={styles.actionItemFilterTitle}
        >
          Show
        </TypographyPoppins>
        <div
          className={styles.radioBtnGroup}
          onClick={() => handleFilterChange('all')}
        >
          <RadioButton
            state="enabled"
            selected={filters.filterByHub === 'all'}
            classNameInnerRadioSelected={styles.filterRadioInnerSelected}
            classNameOuterRadioSelected={styles.filterRadioOuterSelected}
          />
          <TypographyPoppins
            type="body"
            size="M"
            className={styles.actionItemFilterTitle}
          >
            everything
          </TypographyPoppins>
        </div>
        <div
          className={styles.radioBtnGroup}
          onClick={() => handleFilterChange('hub')}
        >
          <RadioButton
            state="enabled"
            selected={filters.filterByHub === 'hub'}
            classNameInnerRadioSelected={styles.filterRadioInnerSelected}
            classNameOuterRadioSelected={styles.filterRadioOuterSelected}
          />
          <TypographyPoppins
            type="body"
            size="M"
            className={styles.actionItemFilterTitle}
            style={{ width: '60px' }}
          >
            items in
          </TypographyPoppins>
        </div>
        <HubSearch
          selectedHub={filters.hub}
          onResultClick={(hub) => {
            updatedHubFilter({
              id: hub.id,
              name: hub.name,
            });
          }}
        >
          <FilterChip
            isDisabled={filters.filterByHub === 'all'}
            isSelected={true}
          >
            {filters.hub ? filters.hub.name : hubs.length ? hubs[0].name : ''}
          </FilterChip>
        </HubSearch>
      </div>
    </>
  );
};
