import { createEvent, createStore } from 'effector';

import { deleteBoardModalClosed } from '@src/entities/delete-modals/modal';

export const boardIdCleaned = createEvent();

export const boardIdPassed = createEvent<string>();

// TODO:
export const $boardId = createStore('');

$boardId
  .on(boardIdPassed, (_, id) => id)
  .reset([boardIdCleaned, deleteBoardModalClosed]);
