import { ChangeEvent, useState } from 'react';

import { AxiosResponse } from 'axios';
import cn from 'classnames';
import { useUnit } from 'effector-react';
import { useParams } from 'react-router';

import {
  Button,
  Dropdown,
  Item,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { Icon } from '@visualist/icons';

import { ColorTheme } from '@api/index';
import { useCreatePaletteCopy } from '@pages/FileCard/palette/model/queries/useCreatePaletteCopy';
import { UseMutateFunction } from '@tanstack/react-query';

import { $palette, paletteReseted } from '../../palette/model';
import { $isPaletteModified, paletteSaved } from '../model/options-managment';
import {
  $adjustmentParams,
  brightnessChanged,
  hueChanged,
  saturationChanged,
  temperatureChanged,
} from './model';

import styles from './styles.module.css';

type Props = {
  fileId: string;
  editColor: UseMutateFunction<AxiosResponse<any>, Error, ColorTheme, unknown>;
};

export const AdjustPalette = ({ fileId, editColor }: Props) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const palette = useUnit($palette);
  const adjustmentParams = useUnit($adjustmentParams);
  const isPaletteModified = useUnit($isPaletteModified);

  const { board_id } = useParams<{ board_id?: string }>();

  const { createPaletteCopy } = useCreatePaletteCopy();

  const saveChanges = () => {
    if (isPaletteModified) {
      editColor({
        block: fileId,
        swatches: palette.swatches,
        theme: palette.theme,
      });
      paletteSaved();
    }
  };

  const menuItems: Item<Variant>[] = [
    {
      content: 'Save as a new copy',
      onClick: () => {
        createPaletteCopy({
          id: fileId,
          swatches: palette.swatches,
          board: board_id,
        });
        paletteReseted();
      },
    },
  ];

  return (
    <>
      <TypographyPoppins type="body" size="M" className={styles.label}>
        Adjust each swatch directly or apply changes to the entire palette
        below.
      </TypographyPoppins>
      <div className={styles.sliders}>
        <Slider
          label="Hue"
          min="-180"
          max="180"
          value={adjustmentParams.hue}
          onChange={(e) => hueChanged(Number(e.target.value))}
          className={styles.hue}
        />
        <Slider
          label="Saturation"
          min="-100"
          max="100"
          value={adjustmentParams.saturation}
          onChange={(e) => saturationChanged(Number(e.target.value))}
          className={styles.saturation}
        />
        <Slider
          label="Brightness"
          min="-100"
          max="100"
          value={adjustmentParams.brightness}
          onChange={(e) => brightnessChanged(Number(e.target.value))}
          className={styles.brightness}
        />
        <Slider
          label="Temperature"
          min="-100"
          max="100"
          value={adjustmentParams.temperature}
          onChange={(e) => temperatureChanged(Number(e.target.value))}
          className={styles.temperature}
        />
      </div>
      <div className={styles.actions}>
        <Button
          type="outlined"
          label="Cancel"
          onClick={() => paletteReseted()}
          isDisabled={!isPaletteModified}
        />
        <Button
          className={styles.save}
          type="filled"
          label="Save changes to this file"
          trailingIcon={
            <Icon
              name="sprite/chevron-down"
              size={12}
              className={styles.icon}
            />
          }
          onClick={saveChanges}
          action={() => setOpenDropdown(true)}
          isDisabled={!isPaletteModified}
        />
      </div>
      <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
        <Dropdown.Menu side="bottom" align="end" density="-2" sideOffset={-8}>
          {menuItems.map((item, index) => (
            <Dropdown.MenuItem key={index} item={item} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

type SliderProps = {
  label: string;
  min: string;
  max: string;
  value: number;
  className: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Slider = ({
  label,
  min,
  max,
  value,
  onChange,
  className,
}: SliderProps) => {
  return (
    <div className={styles.slider}>
      <div className={styles.header}>
        <label>
          <TypographyPoppins type="body" size="M" className={styles.label}>
            {label}
          </TypographyPoppins>
        </label>
      </div>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        className={cn(styles.colorPicker, className)}
        step={1}
      />
    </div>
  );
};
