import React, { ReactNode } from 'react';

import cn from 'classnames';

import styles from './styles.module.css';

export type Size = {
  body: 'S' | 'M' | 'L' | 'Small strikethrough';
  title: 'S' | 'M' | 'L';
  display: 'S' | 'M' | 'L';
  headline: 'S' | 'M' | 'L' | 'Small semibold';
  label: 'S' | 'M' | 'L' | 'XS';
};

export type Variant = keyof Size;

type Props<T extends Variant> = {
  type: T;
  size: T extends 'body'
    ? Size['body']
    : T extends 'title'
    ? Size['title']
    : T extends 'display'
    ? Size['display']
    : T extends 'headline'
    ? Size['headline']
    : T extends 'label'
    ? Size['label']
    : never;
  className?: string;
  style?: React.CSSProperties;
  children: ReactNode;
};

export const TypographyPoppins = <T extends Variant>({
  type,
  size,
  className,
  style,
  children,
}: Props<T>) => {
  // asDiv is added so that instead of the html element being the text related p, h1, etc it is a simple div. This is to align with semantic html

  // Check if children is a string to determine if we should use asDiv
  const asDiv = typeof children !== 'string';

  if (type === 'display') {
    const displayTag = asDiv ? 'div' : 'h1';
    return React.createElement(
      displayTag,
      {
        className: cn(styles.display, styles.poppins, className, {
          [styles.displayMedium]: size === 'M',
          [styles.displaySmall]: size === 'S',
        }),
        style,
      },
      children,
    );
  }

  if (type === 'headline') {
    const headlineTag = asDiv ? 'div' : 'h2';
    return React.createElement(
      headlineTag,
      {
        className: cn(styles.headline, styles.poppins, className, {
          [styles.headlineMedium]: size === 'M',
          [styles.headlineSmall]: size === 'S',
          [styles.headlineSmallsemibold]: size === 'Small semibold',
        }),
        style,
      },
      children,
    );
  }

  if (type === 'title') {
    const titleTag = asDiv ? 'div' : 'p';
    return React.createElement(
      titleTag,
      {
        className: cn(styles.title, styles.poppins, className, {
          [styles.titleMedium]: size === 'M',
          [styles.titleSmall]: size === 'S',
        }),
        style,
      },
      children,
    );
  }

  if (type === 'body') {
    const bodyTag = asDiv ? 'div' : 'p';
    return React.createElement(
      bodyTag,
      {
        className: cn(styles.body, styles.poppins, className, {
          [styles.bodyMedium]: size === 'M',
          [styles.bodySmall]: size === 'S',
          [styles.bodySmallStrikethrough]: size === 'Small strikethrough',
        }),
        style,
      },
      children,
    );
  }

  if (type === 'label') {
    const labelTag = asDiv ? 'div' : 'span';
    return React.createElement(
      labelTag,
      {
        className: cn(styles.label, styles.poppins, className, {
          [styles.labelMedium]: size === 'M',
          [styles.labelSmall]: size === 'S',
          [styles.labelXSmall]: size === 'XS',
        }),
        style,
      },
      children,
    );
  }

  throw Error('Typography Poppins is returning nothing');

  return null;
};
