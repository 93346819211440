import { useHistory } from 'react-router';

import { createDesign, getDesign, updateDesignName } from '@api/designs';
import { openedLibrary } from '@pages/StudioPage/model';
import { studioDesignKeys } from '@src/shared/constants/query-keys';
import { useMutation, useQuery } from '@tanstack/react-query';

type Props = {
  designId: string;
};

export const useDesign = ({ designId }: Props) => {
  const history = useHistory();
  // const params = useParams<{ design_id: string }>();

  const designQuery = useQuery({
    queryKey: studioDesignKeys.design(designId),
    queryFn: () => getDesign(designId),
    select: (data) => data.data,
  });

  const createDesignMutation = useMutation({
    mutationFn: createDesign,
    onSuccess: (res) => {
      history.replace(`/studio/${res.data.id}`);
      setTimeout(() => {
        openedLibrary();
      }, 500);
    },
  });

  const updateDesignNameMutation = useMutation({
    mutationFn: updateDesignName,
  });

  return {
    designQuery,
    createDesignMutation,
    updateDesignNameMutation,
  };
};
