import {
  ChangeEvent,
  createContext,
  CSSProperties,
  DragEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';
import { debounce } from 'lodash';

import { Icon } from '@visualist/icons';

import { Button } from '../Buttons';
import { Divider } from '../Divider';
import { IconButton } from '../IconButtons';
import { TypographyPoppins } from '../Styles/Typography/TypographyPoppins';
import { Tab } from '../Tabs';
import { TooltipRadix } from '../tooltip-radix';

import styles from './styles.module.css';

type Tabs = 'color options' | 'upload image';

type Swatch = {
  name: string;
  color: string;
};

export type Swatches = {
  title?: string;
  swatches?: Swatch[];
};

type Props = {
  colors: Swatches;
  gradients?: Swatches;
  isCustomColor: boolean | null;
  background: string;
  selectedBackground: string | { full_size: string };
  backgroundSelected: (color: string) => void;
  colorMenuClosed: () => void;
  isDebounce?: boolean;
  isTabs?: boolean;
  selectFile?: ({
    file,
    coverColor,
  }: {
    file: string | FileList;
    coverColor: 'light' | 'dark';
  }) => void;
  style?: CSSProperties;
};

const Context = createContext<{
  background: string;
  selectedBackground: string | { full_size: string };
  backgroundSelected: (color: string) => void;
  isCustomColor: boolean | null;
  colorMenuClosed: () => void;
  isDebounce?: boolean;
}>({
  background: '',
  selectedBackground: '',
  backgroundSelected: () => {},
  isCustomColor: null,
  colorMenuClosed: () => {},
  isDebounce: false,
});

export const ColorMenu = ({
  colors,
  gradients,
  background,
  selectedBackground,
  backgroundSelected,
  isCustomColor,
  colorMenuClosed,
  isDebounce,
  isTabs = false,
  selectFile,
  style,
}: Props) => {
  const [activeTab, setActiveTab] = useState<Tabs>('color options');

  return (
    <Context.Provider
      value={{
        background,
        selectedBackground,
        backgroundSelected,
        isCustomColor,
        colorMenuClosed,
        isDebounce,
      }}
    >
      {isTabs && (
        <div
          style={{
            display: 'flex',
          }}
        >
          <Tab
            className={cn({
              [styles.tab]: activeTab === 'upload image',
            })}
            onClickTab={() => setActiveTab('color options')}
            isSelected={activeTab === 'color options'}
          >
            Color options
          </Tab>
          <Tab
            className={cn({
              [styles.tab]: activeTab === 'color options',
            })}
            onClickTab={() => setActiveTab('upload image')}
            isSelected={activeTab === 'upload image'}
          >
            Upload image
          </Tab>
        </div>
      )}
      <div style={style} className={styles.colorMenu}>
        {activeTab === 'color options' && (
          <>
            <Swatches title={colors.title} swatches={colors.swatches} />
            <Swatches title={gradients?.title} swatches={gradients?.swatches} />
            <CustomColor />
          </>
        )}
        {activeTab === 'upload image' && (
          <Placeholder selectFile={selectFile} />
        )}
      </div>
    </Context.Provider>
  );
};

const Swatches = ({ title, swatches }: Swatches) => {
  if (!swatches) return null;

  return (
    <>
      {title && (
        <TypographyPoppins type="label" size="S" className={styles.label}>
          {title}
        </TypographyPoppins>
      )}
      <ul className={styles.swatches}>
        {swatches.map((swatch, i) => {
          return (
            <li key={i}>
              <ColorSwatch name={swatch.name} color={swatch.color} />
            </li>
          );
        })}
      </ul>
      <Divider type="long-line" className={styles.divider} />
    </>
  );
};

const ColorSwatch = ({ name, color }: { name: string; color: string }) => {
  if (!color) return null;

  const {
    background,
    selectedBackground,
    backgroundSelected,
    colorMenuClosed,
  } = useContext(Context);

  const isSelectedColor = () => {
    if (selectedBackground && selectedBackground === color) {
      return true;
    } else if (!selectedBackground && background === color) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <TooltipRadix description={name}>
      <div
        style={{ background: color }}
        className={cn(styles.swatch, {
          [styles.light]: color.includes('#') && isLightColor(hexToRgb(color)),
          [styles.dark]: color.includes('#') && !isLightColor(hexToRgb(color)),
        })}
        onClick={() => {
          backgroundSelected(color);
          colorMenuClosed();
        }}
      >
        {isSelectedColor() && (
          <Icon
            size={16}
            name="sprite/tick"
            color="var(--color-neutral-variant-30)"
          />
        )}
      </div>
    </TooltipRadix>
  );
};

const CustomColor = () => {
  const ref = useRef<HTMLInputElement>(null);

  const {
    background,
    selectedBackground,
    isCustomColor,
    backgroundSelected,
    isDebounce,
  } = useContext(Context);

  const getLightColor = () => {
    if (
      selectedBackground &&
      typeof selectedBackground === 'string' &&
      selectedBackground.includes('#')
    ) {
      return isLightColor(hexToRgb(selectedBackground));
    } else if (!selectedBackground && background.includes('#')) {
      return isLightColor(hexToRgb(background));
    }
  };

  const isGradient =
    (selectedBackground &&
      typeof selectedBackground === 'string' &&
      !selectedBackground.includes('#')) ||
    (background && !background.includes('#'));

  const debouncedColorChange = useRef(
    debounce((color) => {
      backgroundSelected(color);
    }, 500),
  ).current;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isDebounce) {
      debouncedColorChange(e.target.value);
    } else {
      backgroundSelected(e.target.value);
    }
  };

  return (
    <>
      <TypographyPoppins type="label" size="S" className={styles.label}>
        Custom
      </TypographyPoppins>
      <div style={{ display: 'flex', gap: '4px' }}>
        {isCustomColor && selectedBackground === 'string' && (
          <TooltipRadix
            description={
              selectedBackground
                ? selectedBackground.toUpperCase()
                : background.toUpperCase()
            }
          >
            <div
              style={{
                background: selectedBackground
                  ? selectedBackground
                  : background,
              }}
              className={cn(styles.swatch, {
                [styles.light]: !isGradient && getLightColor(),
                [styles.dark]: !isGradient && !getLightColor(),
              })}
            >
              <Icon
                size={16}
                name="sprite/tick"
                color="var(--color-neutral-variant-30)"
              />
            </div>
          </TooltipRadix>
        )}
        <IconButton
          className={styles.customColor}
          iconStyles={styles.customColorIcon}
          type="unfilled"
          icon={
            <>
              <Icon
                name="sprite/plus"
                color="var(--color-neutral-variant-30)"
              />
              <div className={styles.colorWheelMenu}>
                <input ref={ref} type="color" onChange={onChange} />
              </div>
            </>
          }
          onClick={() => ref.current?.click()}
        />
      </div>
    </>
  );
};

const Placeholder = ({
  selectFile,
}: {
  selectFile?: ({
    file,
    coverColor,
  }: {
    file: string | FileList;
    coverColor: 'light' | 'dark';
  }) => void;
}) => {
  const [coverColor, setCoverColor] = useState<'dark' | 'light' | null>(null);
  const [file, setFile] = useState<FileList | null>(null);

  const uploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (coverColor && file && selectFile) {
      selectFile({
        file,
        coverColor,
      });
    }
  }, [coverColor, file]);

  const analyzePixelsImage = (src: string | ArrayBuffer) => {
    if (!src) return;

    const img = new Image();
    img.src = src as string;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');

      if (!ctx) return;

      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      const data = imageData.data;

      let totalBrightness = 0;

      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const brightness = RED_Y * r + GREEN_Y * g + BLUE_Y * b;

        totalBrightness += brightness;
      }

      const avgBrightness = totalBrightness / (img.width * img.height);
      const coverColor = avgBrightness < 128 ? 'dark' : 'light';

      setCoverColor(coverColor);
    };
  };

  const onChange = () => {
    const { files } = uploadRef.current as HTMLInputElement;

    if (files) {
      setFile(files);
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          analyzePixelsImage(e.target.result);
        }
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = e.dataTransfer.files;
      setFile(files);
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          analyzePixelsImage(e.target.result);
        }
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <input
        ref={uploadRef}
        style={{ display: 'none' }}
        type="file"
        accept=".png, .jpg, .jpeg"
        onChange={onChange}
      />
      <div
        className={styles.placeholder}
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        <Icon name="sprite/image" color="var(--color-neutral-variant-30)" />
        <div className={styles.action}>
          <TypographyPoppins
            type="body"
            size="M"
            style={{
              color: 'var(--color-neutral-variant-30)',
            }}
          >
            Drop picture here or
          </TypographyPoppins>
          <Button
            className={styles.finder}
            type="text"
            label="open Finder"
            onClick={() => uploadRef.current?.click()}
          />
        </div>
        <TypographyPoppins
          type="body"
          size="S"
          style={{
            textAlign: 'center',
            color: 'var(--color-neutral-variant-30)',
          }}
        >
          Use images wider than 1500 pixels. The maximum size is 15 MB.
        </TypographyPoppins>
      </div>
    </>
  );
};

// TODO: copying variables and functions from app/shared as they cannot be accessed from DS
const RED_Y = 0.2126;
const GREEN_Y = 0.7152;
const BLUE_Y = 0.0722;

export const hexToRgb = (hex: string | null) => {
  if (!hex) return null;

  if (hex.includes('#')) {
    hex = hex.replace(/^#/, '');
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
};

export const isLightColor = (rgb: string | null) => {
  if (!rgb) return null;

  const rgbColors = rgb.split(',');
  const r = parseInt(rgbColors[0]);
  const g = parseInt(rgbColors[1]);
  const b = parseInt(rgbColors[2]);
  const brightness = RED_Y * r + GREEN_Y * g + BLUE_Y * b;

  const isLight = brightness > 128;

  return isLight;
};
