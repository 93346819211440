import { useRef, useState } from 'react';

import { useOnClickOutside } from '@visualist/hooks';

import { ChangingPermissions } from '@api/boards';
import { Member } from '@api/docs';
import { Member as MemberHub } from '@api/hubs';
import { PermissionsHub } from '@api/hubs';
import { UseMutateFunction } from '@tanstack/react-query';

import { User } from '..';

type InvitedUserProps = {
  user: Member | MemberHub;
  permissions: PermissionsHub[];
  chahgePermissions: UseMutateFunction<
    ChangingPermissions,
    any,
    ChangingPermissions,
    unknown
  >;
  removeMember: (invitee: Member | MemberHub) => void;
};

export const InvitedUser = ({
  removeMember,
  user,
  chahgePermissions,
  permissions,
}: InvitedUserProps) => {
  const [on, setOn] = useState(true);
  const [isShowMenu, setShowMenu] = useState(false);

  const permissionsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(permissionsRef, () => {
    if (isShowMenu) {
      setShowMenu(false);
    }
  });

  const getPermissions = (user: Member) => {
    if (user.permissions.includes('edit')) {
      return 'Editor';
    } else if (user.permissions.includes('comment')) {
      return 'Commenter';
    } else {
      return 'Viewer';
    }
  };

  const changePermissions = (permissions: PermissionsHub[]) => {
    // @ts-expect-error So console logging this user there is no .board with it, but incase im having ts expect an error since there may be other cases where there is a board id?
    chahgePermissions({ boardId: user.board, member: user.id, permissions });
  };

  const remove = () => {
    if (on) {
      removeMember(user);
    }
    setOn(false);
  };

  return (
    <User
      user={user}
      isShowMenu={isShowMenu}
      setShowMenu={setShowMenu}
      on={on}
      permissionsRef={permissionsRef}
      permissions={permissions}
      getPermissions={getPermissions}
      changePermissions={changePermissions}
      remove={remove}
    />
  );
};
