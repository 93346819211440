import cn from 'classnames';
import { useParams } from 'react-router';

import { DocumentHead } from '@components/DocumentHead';
import { LibraryActions } from '@components/LibraryActions';
import { ErrorBoundary } from '@sentry/react';
import { Editor } from '@src/entities/doc/ui/editor';
import { NavPanel } from '@src/entities/doc/ui/nav-panel';
import { PageSetupWrapper } from '@src/entities/doc/ui/page-setup';
import { DocTabs } from '@src/entities/doc/ui/tabs';
import { SomethingWentWrong } from '@src/entities/global-error/premade/something-went-wrong';
import { SearchDialog } from '@src/entities/search/ui/searchDialog';
import { clearAllTabData } from '@src/shared/utils/clear-all-local-data';

import styles from './styles.module.css';

import '@fontsource-variable/caveat';
import '@fontsource-variable/montserrat';
import '@fontsource-variable/noto-serif';
import '@fontsource-variable/nunito';
import '@fontsource-variable/open-sans';
import '@fontsource-variable/oswald';
import '@fontsource-variable/playfair-display';
import '@fontsource/alex-brush';
import '@fontsource/arvo';
import '@fontsource/cormorant-garamond';
import '@fontsource/lato';
import '@fontsource/merriweather';
import '@fontsource/monsieur-la-doulaise';
import '@fontsource/rouge-script';
import '@fontsource/zeyada';

export const DocPageV2 = () => {
  const { doc_id } = useParams<{ doc_id: string }>();

  return (
    <div className={styles.container}>
      <DocumentHead name="Docs" />
      <SearchDialog hideMobileSearch />
      <div className={cn(styles.docContainer)}>
        <ErrorBoundary
          fallback={<SomethingWentWrong crashSideEffect={clearAllTabData} />}
        >
          <DocTabs />
          <NavPanel />
          <Editor key={doc_id} docId={doc_id} />
        </ErrorBoundary>
      </div>
      <PageSetupWrapper docId={doc_id} />
      <LibraryActions page="docPage" />
    </div>
  );
};
