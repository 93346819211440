import { Dispatch } from 'react';

import { useUnit } from 'effector-react';

import { Button } from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { ColorSwatch } from '@api/index';
import {
  $hex,
  $hsl,
  $hsv,
  $rgb,
} from '@pages/FileCard/palette-options/color-codes/model/codes-managment';

import styles from './styles.module.css';

type Props = {
  isOpenColorPicker: boolean;
  index: number;
  setHoveredIndex: Dispatch<number | null>;
  swatch: ColorSwatch;
  setHoveredSwatch: Dispatch<number | null>;
};

export const ColorCodes = ({
  isOpenColorPicker,
  index,
  setHoveredIndex,
  swatch,
  setHoveredSwatch,
}: Props) => {
  const hex = useUnit($hex);
  const rgb = useUnit($rgb);
  const hsl = useUnit($hsl);
  const hsv = useUnit($hsv);

  const copyCode = async (code: string) => {
    try {
      await navigator.clipboard.writeText(code);

      startedSnack({
        label: 'Copied code',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy code",
        action: {
          label: 'Try again',
          action: () => {
            copyCode(code);
          },
        },
        close: true,
      });
    }
  };

  return (
    <div
      className={styles.codes}
      onClick={() => {
        if (!isOpenColorPicker) {
          setHoveredIndex(index);
          setHoveredSwatch(index);
        }
      }}
    >
      {hex && (
        <Button
          className={styles.code}
          type="ghost"
          label={swatch.hex_color.toUpperCase().replace(/^#/, '')}
          fontType="label"
          size="S"
          onClick={() => copyCode(swatch.hex_color.replace(/^#/, ''))}
        />
      )}
      {rgb && (
        <Button
          className={styles.code}
          type="ghost"
          label={swatch.rgb_color.replace(/,/g, ', ')}
          fontType="label"
          size="S"
          onClick={() => copyCode(swatch.rgb_color.replace(/,/g, ', '))}
        />
      )}
      {hsl && (
        <Button
          className={styles.code}
          type="ghost"
          label={swatch.hsl_color.replace(/%/g, '').replace(/,/g, ', ')}
          fontType="label"
          size="S"
          onClick={() =>
            copyCode(swatch.hsl_color.replace(/%/g, '').replace(/,/g, ', '))
          }
        />
      )}
      {hsv && (
        <Button
          className={styles.code}
          type="ghost"
          label={swatch.hsv_color.replace(/%/g, '').replace(/,/g, ', ')}
          fontType="label"
          size="S"
          onClick={() =>
            copyCode(swatch.hsv_color.replace(/%/g, '').replace(/,/g, ', '))
          }
        />
      )}
    </div>
  );
};
