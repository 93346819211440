import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router';

import {
  IconButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { themeUnselected } from '../color-themes/model/active-theme';
import {
  themeCreationCompleted,
  themePlaceholderCleared,
} from '../color-themes/model/create-theme';
import { fileCardIdUnset } from '../model';

import styles from './styles.module.css';

export const Close = () => {
  const history = useHistory();
  const location = useLocation();

  const close = () => {
    fileCardIdUnset();
    themeUnselected();
    themePlaceholderCleared();
    themeCreationCompleted();
    history.replace(location.pathname);
  };

  return (
    <TooltipRadix description="Close" side={isMobile ? 'bottom' : 'left'}>
      <IconButton
        type="unfilled"
        className={styles.iconButton}
        icon={<Icon name="sprite/x" className={styles.icon} />}
        onClick={close}
      />
    </TooltipRadix>
  );
};
