import { AxiosResponse } from 'axios';
import { useUnit } from 'effector-react';
import cloneDeep from 'lodash/cloneDeep';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { updateDesignThumbnail } from '@api/designs';
import {
  cropImage,
  deleteImage,
  File,
  getImages,
  ImageGenericBlockResponse,
  ImageResponse,
  removeBackground,
  updateImage,
  UpdateImageDataWithoutDesignId,
  uploadExistingImages,
  uploadImage,
} from '@pages/StudioPage/api';
import {
  $draggableImages,
  $selectedImages,
  $singleSelectedImage,
  singleImageUnselected,
} from '@pages/StudioPage/components/Library/model';
import {
  addedOptimisticImage,
  openedVaiTidyupModal,
  removedOptimisticImage,
  selectedImageBlockIdsAction,
} from '@pages/StudioPage/model';
import { studioDesignKeys } from '@src/shared/constants/query-keys';
import { IMAGE_PREVIEW_MUTATION } from '@src/shared/constants/query-names';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// import { useLayer } from '../useLayer';

type Props = {
  designId: string;
};

const FIVE_MINS_IN_MS = 1000 * 60 * 5;

export const useImages = ({ designId }: Props) => {
  const queryClient = useQueryClient();
  // const mutateLayer = useLayer();
  const singleSelectedImage = useUnit($singleSelectedImage);
  const selectedImages = useUnit($selectedImages);
  const draggableImages = useUnit($draggableImages);

  const imageQuery = useQuery({
    queryKey: studioDesignKeys.images(designId),
    queryFn: () => getImages(designId),
    refetchOnReconnect: false,
    retry: false,
    staleTime: FIVE_MINS_IN_MS,
  });

  const previewMutation = useMutation({
    mutationFn: ({ file }: { file: string }) =>
      updateDesignThumbnail(designId, file),
    mutationKey: [IMAGE_PREVIEW_MUTATION],
  });

  const imageUpdateMutation = useMutation({
    mutationFn: (
      props: UpdateImageDataWithoutDesignId & {
        shouldInvalidate?: boolean;
      },
    ) =>
      updateImage({
        designId,
        ...props,
      }),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: studioDesignKeys.images(designId),
      });

      const queryData = queryClient.getQueryData<
        AxiosResponse<ImageGenericBlockResponse>
      >(studioDesignKeys.images(designId));

      const imageToUpdateIndex = queryData?.data.results.findIndex(
        (ib) => ib.id === variables.imageId,
      );

      if (typeof imageToUpdateIndex === 'undefined') return;

      const imageToUpdate = queryData?.data.results[imageToUpdateIndex];

      if (!imageToUpdate) return;

      const updatedImage: typeof imageToUpdate = {
        ...imageToUpdate,
        studio: {
          ...imageToUpdate.studio,
          position_height: variables.positionHeight,
          position_width: variables.positionWidth,
          position_x: variables.positionX,
          position_y: variables.positionY,
          position_omega: variables.positionOmega,
          position_lock: variables.positionLock,
          position: {
            ...imageToUpdate.studio.position,
            position_height: variables.positionHeight,
            position_width: variables.positionWidth,
            position_x: variables.positionX,
            position_y: variables.positionY,
            position_omega: variables.positionOmega,
            position_lock: variables.positionLock,
          },
        },
      };

      const newResults = [...queryData.data.results];

      newResults[imageToUpdateIndex] = updatedImage;

      const newQueryData = {
        ...queryData,
        data: {
          ...queryData.data,
          results: newResults,
        },
      };

      queryClient.setQueryData(studioDesignKeys.images(designId), newQueryData);
    },
    onSuccess: (_, variables) => {
      if (variables.shouldInvalidate) {
        queryClient.invalidateQueries({
          queryKey: studioDesignKeys.images(designId),
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });
    },
  });

  const imageCropMutation = useMutation({
    mutationFn: cropImage,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      }),
  });

  const imageUploadMutation = useMutation({
    mutationFn: async ({ forms }: { forms: FormData[] }) => {
      const promises = [];
      for (const element of forms) {
        promises.push(uploadImage(element));
      }

      return await Promise.allSettled(promises);
    },
    onMutate: async (variables) => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });

      // Notify user
      startedSnack({
        label: 'Adding images...',
        close: true,
      });

      // Optimistically add an image to the canvas while image uploads
      for (const form of variables.forms) {
        try {
          const file = form.get('file');

          if (!(file instanceof Blob)) {
            console.error('File is not a Blob');
            continue;
          }

          const { posX, posY, width, height, id } = getImageData(form);

          const newWidth = width;
          const newHeight = height;

          // if (imageQuery.data?.data) {
          //   // Averages the width of all images to get a similar width for the new image
          //   const averageWidth = getAverage(
          //     imageQuery.data.data.results.map(
          //       (r) => r.studio.position_width || BASE_IMAGE_WIDTH,
          //     ),
          //   );
          //   const averageHeight = (averageWidth * height) / width;
          //   newWidth = averageWidth;
          //   newHeight = averageHeight;
          // }

          const imgURL = URL.createObjectURL(file);

          addedOptimisticImage({
            id,
            imageURL: imgURL,
            position: {
              x: posX,
              y: posY,
            },
            height: newHeight,
            width: newWidth,
          });
        } catch (e) {
          console.error(e);
          continue;
        }
      }
    },
    onSuccess: async (data, variables) => {
      // We Assume the form order and the response order is the same. Promise.allSettled should guarantee this, as long as we always use form.variables as our source of truth

      // Update position after uploading image
      // TODO improve in the future to not have to do this i.e. send in the first request
      for (const [index, form] of variables.forms.entries()) {
        const { height, width, id } = getImageData(form);

        const newWidth = width;
        const newHeight = height;

        // if (imageQuery.data?.data) {
        //   // Averages the width of all images to get a similar width for the new image
        //   const averageWidth = getAverage(
        //     imageQuery.data.data.results.map(
        //       (r) => r.studio.position_width || BASE_IMAGE_WIDTH,
        //     ),
        //   );
        //   const averageHeight = (averageWidth * height) / width;
        //   newWidth = averageWidth;
        //   newHeight = averageHeight;
        // }

        if (data[index].status !== 'fulfilled') {
          // TODO Error out
          removedOptimisticImage({ id });
          const rejectedError = data[index] as PromiseRejectedResult;
          throw Error(rejectedError.reason);
        }

        const imageResponse = data[index] as PromiseFulfilledResult<
          AxiosResponse<ImageResponse>
        >;

        try {
          const uploadedImageData = imageResponse.value.data;

          // mutateLayer.mutate({
          //   designId,
          //   imageId: uploadedImageData.id,
          //   typeOfLayerAction: 'backward',
          // });

          const file = form.get('file');

          if (!(file instanceof Blob)) {
            console.error('File is not a Blob');
            continue;
          }

          await imageUpdateMutation.mutateAsync({
            imageId: uploadedImageData.id,
            positionX: uploadedImageData.position_x,
            positionY: uploadedImageData.position_y,
            positionHeight: newHeight,
            positionWidth: newWidth,
            positionLock: false,
            positionOmega: 0,
          });

          removedOptimisticImage({ id });

          // Add image to the images query so it shows up on cavnas, this gets replaced by the server data on refetch
          const imagesQuery:
            | AxiosResponse<ImageGenericBlockResponse>
            | undefined = queryClient.getQueryData(
            studioDesignKeys.images(designId),
          );

          if (!imagesQuery) {
            console.error('No image data in query cache');
            continue;
          }

          const newQueryData = cloneDeep(imagesQuery);

          // Create a new image block
          newQueryData.data.results.push({
            id: uploadedImageData.id,
            file: uploadedImageData.file,
            block_type: 'Image',
            created_at: new Date(),
            height: height,
            width: width,
            is_removed: false,
            name: '',
            studio: {
              crop: {
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
                omega: 0,
              },
              position_x: uploadedImageData.position_x,
              position_y: uploadedImageData.position_y,
              position_height: newHeight,
              position_width: newWidth,
              position_lock: false,
              position_omega: 0,
              position: {
                position_x: uploadedImageData.position_x,
                position_y: uploadedImageData.position_y,
                position_height: newHeight,
                position_width: newWidth,
                position_lock: false,
                position_omega: 0,
              },
              layer: newQueryData.data.results.length,
            },
            boards: [],
          });

          queryClient.setQueryData(
            studioDesignKeys.images(designId),
            newQueryData,
          );

          if (newQueryData.data.results.length === 4) {
            // Open tiny Vai popup modal
            openedVaiTidyupModal();
          }
        } catch (e) {
          removedOptimisticImage({ id: id });

          queryClient.invalidateQueries({
            queryKey: studioDesignKeys.images(designId),
          });

          // Retry Snack bar
          startedSnack({
            label: "Couldn't upload image",
            action: {
              label: 'Try again',
              action: () => {
                imageUploadMutation.mutate({ forms: [form] });
              },
            },
            close: true,
          });
          console.error(e);
          continue;
        } finally {
          queryClient.invalidateQueries({
            queryKey: studioDesignKeys.images(designId),
          });
        }
      }
    },
    onError: (e) => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });
      console.error(e);
    },
  });

  const existingImageUploadMutation = useMutation({
    mutationFn: async ({ id, files }: { id: string; files: File[] }) => {
      return await uploadExistingImages({ id, files });
    },
    onMutate: (variables) => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });

      // Notify user
      startedSnack({
        label: 'Adding images...',
        close: true,
      });

      // Optimistically add an image to the canvas while image uploads
      try {
        if (selectedImages && !draggableImages && !singleSelectedImage) {
          addedOptimisticImage({
            id: variables.files[variables.files.length - 1].file,
            imageURL: selectedImages[selectedImages.length - 1].ref,
            position: {
              x: variables.files[variables.files.length - 1].position
                .position_x,
              y: variables.files[variables.files.length - 1].position
                .position_y,
            },
            height:
              variables.files[variables.files.length - 1].position
                .position_height,
            width:
              variables.files[variables.files.length - 1].position
                .position_width,
          });
        } else if (draggableImages) {
          addedOptimisticImage({
            id: variables.files[0].file,
            imageURL: draggableImages[0].ref,
            position: {
              x: variables.files[0].position.position_x,
              y: variables.files[0].position.position_y,
            },
            height: variables.files[0].position.position_height,
            width: variables.files[0].position.position_width,
          });
        } else if (singleSelectedImage) {
          addedOptimisticImage({
            id: variables.files[0].file,
            imageURL: singleSelectedImage[0].ref,
            position: {
              x: variables.files[0].position.position_x,
              y: variables.files[0].position.position_y,
            },
            height: variables.files[0].position.position_height,
            width: variables.files[0].position.position_width,
          });
          singleImageUnselected();
        }
      } catch (e) {
        console.error(e);
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });

      removedOptimisticImage({
        id: variables.files[variables.files.length - 1].file,
      });
    },
    onError: (e) => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });
      console.error(e);
    },
  });

  const imageBackgroundRemove = useMutation({
    mutationFn: (id: string) => removeBackground(id),
    onMutate: () => {
      startedSnack({
        label: 'Magic in progress...',
        close: true,
      });
    },
    onSuccess: (d, currentImageId) => {
      // Attempt to optimistically replace the block with the new removed bg
      const currentData = queryClient.getQueryData<
        AxiosResponse<ImageGenericBlockResponse>
      >(studioDesignKeys.images(designId));

      if (!currentData) {
        // No data? just default to invalidate the query
        queryClient.invalidateQueries({
          queryKey: studioDesignKeys.images(designId),
        });
        return;
      }

      const newData = Object.assign({}, currentData);

      newData.data.results.findIndex((b) => {
        if (b.id === currentImageId) {
          b.file = d.data.file;
          return true;
        }
        return false;
      });

      queryClient.setQueryData(studioDesignKeys.images(designId), newData);
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });
    },
  });

  const imageDeleteMutation = useMutation({
    mutationFn: (ids: string[]) => {
      return deleteImage(designId, ids);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });
      selectedImageBlockIdsAction(new Set());
    },
  });

  return {
    imageQuery,
    imageUploadMutation,
    existingImageUploadMutation,
    imageDeleteMutation,
    imageBackgroundRemove,
    imageUpdateMutation,
    imageCropMutation,
    previewMutation,
  };
};

const getImageData = (form: FormData) => {
  const posXString = form.get('position_x');
  const posYString = form.get('position_y');
  const widthString = form.get('width');
  const heightString = form.get('height');
  const id = form.get('id');

  if (
    !posXString ||
    !posYString ||
    typeof posXString !== 'string' ||
    typeof posYString !== 'string' ||
    !widthString ||
    !heightString ||
    typeof widthString !== 'string' ||
    typeof heightString !== 'string' ||
    !id ||
    typeof id !== 'string'
  ) {
    throw Error('Form data missing');
  }

  const posX = parseInt(posXString);
  const posY = parseInt(posYString);
  const width = parseInt(widthString);
  const height = parseInt(heightString);

  return {
    posX,
    posY,
    width,
    height,
    id,
  };
};
