import { ReactNode } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { useHistory, useLocation } from 'react-router';

import {
  IconButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { getHubs } from '@api/hubs';
import {
  $isFileCardOpen,
  $isOpenFileCardDotMenu,
  fileCardDotMenuClosed,
} from '@pages/FileCard/model';
import { LAST_OPENED_MESSAGE_HUB } from '@pages/messages/constants';
import { $hubDeleteModalState } from '@src/entities/delete-modals/modal';
import { ALL_HUBS_QUERY } from '@src/shared/constants';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';
import { useQuery } from '@tanstack/react-query';

import { toggleMobileSidebar } from '../../model';
import { Avatar } from '../avatar';
import { NavRails } from '../navrails';
import { VaiButton } from '../vai';

import styles from './styles.module.css';

export const Navigation = () => {
  const [isFileCardOpen, isOpenFileCardDotMenu, hubDeleteModalState] = useUnit([
    $isFileCardOpen,
    $isOpenFileCardDotMenu,
    $hubDeleteModalState,
  ]);

  const handleBackdropClick = () => {
    if (isOpenFileCardDotMenu) {
      fileCardDotMenuClosed();
    }
  };

  return (
    <div
      className={cn(styles.navigation, {
        [styles.confirmationDialogOpened]: !!hubDeleteModalState,
      })}
      onClick={handleBackdropClick}
    >
      {isFileCardOpen ? <NavRails /> : <NavBars />}
    </div>
  );
};

const NavBars = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const { data: hubs = [] } = useQuery({
    queryKey: [ALL_HUBS_QUERY],
    queryFn: () => getHubs({ ordering: '-created_at' }),
  });

  const [lastOpenedMessageHub, setLastOpenedMessageHub] = useLocalStorage(
    LAST_OPENED_MESSAGE_HUB,
    hubs.length ? hubs[0].id : undefined,
  );

  const goToMessageCenter = () => {
    if (lastOpenedMessageHub) {
      // If last opened hub is on local storage use that
      history.push(`/messages/${lastOpenedMessageHub}`);
    } else {
      if (hubs.length > 0) {
        // Use first hub result from api
        setLastOpenedMessageHub(hubs[0].id);
        history.push(`/messages/${hubs[0].id}`);
      } else {
        history.push('/messages');
      }
    }
  };

  return (
    <>
      <div className={styles.topBar}>
        <div className={styles.leftSide}>
          <IconButton
            type="unfilled"
            icon={<Icon name="sprite/menu-hamburger-left" />}
            isSelected={false}
            onClick={() => {
              toggleMobileSidebar();
            }}
          />
        </div>
        <div className={styles.centerSide}>
          {/* <Search /> */}
          <VaiButton />
        </div>
        <div className={styles.rightSide}>
          <Avatar />
        </div>
      </div>

      <div className={styles.bottomBar}>
        <NavButton
          icon={<Icon name="sprite/home" />}
          isSelected={pathname.includes('/home')}
          onClick={() => history.push('/home')}
          label="Home"
        />
        <NavButton
          icon={<Icon name="sprite/paper-plane" />}
          isSelected={pathname.includes('/messages')}
          onClick={goToMessageCenter}
          label="Messages"
        />
        <NavButton
          icon={<Icon name="sprite/action-items-list" />}
          isSelected={pathname.includes('/actions')}
          onClick={() => history.push('/action-items')}
          label="Action items"
        />
        {/* <NavButton
          icon={<Icon name="sprite/home" />}
          isSelected={true}
          onClick={() => {}}
          label="Notifications"
        /> */}
      </div>
    </>
  );
};

export const NavButton = ({
  onClick,
  isSelected,
  icon,
  label,
}: {
  onClick: () => void;
  isSelected: boolean;
  icon: ReactNode;
  label: string;
}) => {
  return (
    <div className={styles.navButton}>
      <IconButton
        className={styles.navIcon}
        type="unfilled"
        icon={icon}
        isSelected={isSelected}
        onClick={onClick}
      />
      <TypographyPoppins className={styles.text} type="label" size="M">
        {label}
      </TypographyPoppins>
    </div>
  );
};
