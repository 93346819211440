import cn from 'classnames';
import { useUnit } from 'effector-react';
import { isDesktop } from 'react-device-detect';
import { useParams } from 'react-router';

import {
  AssistChip,
  ColorMenu,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { ThumbnailBrightness } from '@api/hubs';
import { useAddCover } from '@pages/HubPage/model/queries/useAddCover';
import { hexToRgb, isLightColor } from '@src/shared/constants/colours';

import { useRemoveCover } from '../../../../pages/HubPage/model/queries/useRemoveCover';
import {
  $background,
  $colors,
  $defaultColor,
  $gradients,
  $isCustomColor,
  $isOpenColorMenu,
  backgroundSelected,
  colorMenuClosed,
  colorMenuToggled,
  colorPicked,
} from './model';

import styles from './styles.module.css';

interface SelectCoverProps {
  thumbnail: string;
  thumbnailHex: string;
}

export const SelectCover = ({ thumbnail, thumbnailHex }: SelectCoverProps) => {
  const isOpenColorMenu = useUnit($isOpenColorMenu);
  const colors = useUnit($colors);
  const gradients = useUnit($gradients);
  const selectedBackground = useUnit($background);
  const defaultColor = useUnit($defaultColor);
  const isCustomColor = useUnit($isCustomColor);

  const { hub_id } = useParams<{ hub_id: string }>();

  const { addCover } = useAddCover();
  const { removeCover } = useRemoveCover();

  const selectColor = (color: string) => {
    let rgb = null;
    let coverColor: ThumbnailBrightness = 'light';

    if (color.includes('#')) {
      rgb = hexToRgb(color);
      coverColor = isLightColor(rgb) ? 'light' : 'dark';
    }

    colorPicked(color);
    backgroundSelected(color);

    addCover({
      hubId: hub_id,
      thumbnail_hex: color,
      thumbnailBrightness: coverColor,
    });
  };

  const selectFile = ({
    file,
    coverColor,
  }: {
    file: string | FileList;
    coverColor: 'light' | 'dark';
  }) => {
    if (file && coverColor) {
      addCover({
        hubId: hub_id,
        thumbnail: file,
        thumbnailBrightness: coverColor,
      });
      colorPicked(file);
      colorMenuClosed();
    }
  };

  return (
    <div className={styles.cover}>
      {(!thumbnailHex && !thumbnail) || thumbnailHex === defaultColor ? (
        <Popover open={isOpenColorMenu} onOpenChange={() => colorMenuToggled()}>
          <PopoverTrigger asChild>
            <AssistChip
              style="elevated"
              className={styles.addCover}
              leadingIcon
              icon={<Icon name="sprite/image" />}
            >
              Add cover
            </AssistChip>
          </PopoverTrigger>
          <PopoverContent align="end">
            <ColorMenu
              colors={colors}
              gradients={gradients}
              background={thumbnailHex}
              selectedBackground={selectedBackground}
              isCustomColor={isCustomColor}
              backgroundSelected={selectColor}
              colorMenuClosed={colorMenuClosed}
              selectFile={selectFile}
              isTabs
              isDebounce
              style={{ height: '180px' }}
            />
          </PopoverContent>
        </Popover>
      ) : (
        <div
          className={cn(styles.updateCover, {
            [styles.show]: isOpenColorMenu,
            [styles.touchscreenCover]: !isDesktop,
          })}
        >
          <AssistChip
            style="elevated"
            className={styles.deleteCover}
            leadingIcon
            icon={<Icon name="sprite/bin" />}
            onClick={() => {
              removeCover({
                hubId: hub_id,
                thumbnail_hex: defaultColor,
              });
              backgroundSelected('');
            }}
          >
            Remove cover
          </AssistChip>

          <Popover
            open={isOpenColorMenu}
            onOpenChange={() => colorMenuToggled()}
          >
            <PopoverTrigger asChild>
              <AssistChip
                style="elevated"
                className={styles.changeCover}
                leadingIcon
                icon={<Icon name="sprite/image" />}
              >
                Change cover
              </AssistChip>
            </PopoverTrigger>
            <PopoverContent align="end">
              <ColorMenu
                colors={colors}
                gradients={gradients}
                background={thumbnailHex}
                selectedBackground={selectedBackground}
                isCustomColor={isCustomColor}
                backgroundSelected={selectColor}
                colorMenuClosed={colorMenuClosed}
                selectFile={selectFile}
                isTabs
                isDebounce
                style={{ height: '180px' }}
              />
            </PopoverContent>
          </Popover>
        </div>
      )}
    </div>
  );
};
