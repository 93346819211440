import { useState } from 'react';

import { useUnit } from 'effector-react';

import { Divider, SideSheet } from '@visualist/design-system/src/components/v2';

import { Member as MemberHub } from '@api/hubs';

import { Description } from '../ui/description';
import { ExtraSeats } from '../ui/extra-seats';
import { GuestSeat } from '../ui/guest-seat';
import { Invite } from '../ui/invite';
import { InviteField } from '../ui/invite-field';
import { Invitees } from '../ui/invitees';
import { Title } from '../ui/title';
import { ExistingUser } from '../ui/user/existing-user';
import {
  $emails,
  $query,
  emailsCleared,
  emailsEntered,
  queryCleared,
  queryEntered,
} from './model/inviting';
import {
  $id,
  $isHubShareSheetOpen,
  hubShareSheetClosed,
} from './model/open-share-sheet';
import { useInviteToHub } from './model/queries/use-invite-to-hub';
import { useInvitees } from './model/queries/use-invitees';
import { useRemoveInviteeFromHub } from './model/queries/use-remove-from-hub';
import { useSuggestions } from './model/queries/use-suggestions';

import styles from './styles.module.css';

export const HubShareSheet = () => {
  const [isInvite, setInvite] = useState(true);

  const isHubShareSheetOpen = useUnit($isHubShareSheetOpen);
  const id = useUnit($id);
  const query = useUnit($query);
  const emails = useUnit($emails);

  const { suggestionsQuery } = useSuggestions({ query, emails, id });
  const { inviteesQuery } = useInvitees({ id });

  const isNotEnoughSeats = inviteesQuery.data
    ? inviteesQuery.data?.member_seats_available < emails.length
    : false;

  const onSuccess = () => {
    if (emails.length > 0) {
      clear();
    }

    setInvite(true);
  };

  const onError = () => setInvite(true);

  const { sendInvitationHub } = useInviteToHub({ onSuccess, onError });
  const { removeInviteeFromHub } = useRemoveInviteeFromHub();

  const invite = () => {
    if (isInvite && id) {
      sendInvitationHub({
        id,
        users: emails,
        permissions: ['edit', 'invite'],
        // hubName,
      });

      setInvite(false);
    }
  };

  const remove = (member: MemberHub) => {
    // const emailRemovedUser = inviteesQuery.data?.members?.filter(
    //   (invitee) => invitee.email === member.email,
    // )[0].email;

    // const noHubAccess = user.email === emailRemovedUser;

    removeInviteeFromHub({
      id: member.hub,
      members: [member.id],
    });
  };

  const clear = () => {
    queryCleared();
    emailsCleared();
  };

  return (
    <SideSheet
      className={styles.sideSheetContainer}
      type="large"
      showSheet={isHubShareSheetOpen}
      closeSideSheet={hubShareSheetClosed}
      handleBackdropClick={hubShareSheetClosed}
    >
      <SideSheet.Heading
        className={styles.headingContainer}
        closeSideSheet={hubShareSheetClosed}
      >
        Team
      </SideSheet.Heading>
      <SideSheet.Body className={styles.bodyContainer}>
        <SideSheet.ContentContainer>
          <div className={styles.inviteByEmail}>
            <Title
              title="Invite others"
              description="Invite clients and others as collaborators"
            />
            {emails.length > 0 && (
              <GuestSeat
                permissions={['edit', 'invite']}
                setPermissions={() => {}}
              />
            )}
            <InviteField
              isNotEnoughSeats={isNotEnoughSeats}
              suggestions={suggestionsQuery.data ?? []}
              invitation={query}
              setInvitation={(s: string) => queryEntered(s)}
              users={emails}
              setUsers={emailsEntered}
              clear={clear}
            />
            {suggestionsQuery.data &&
              suggestionsQuery.data.length > 0 &&
              emails.length === 0 && (
                <ul className={styles.suggestions}>
                  {suggestionsQuery.data.map((user) => (
                    <ExistingUser
                      key={user.id}
                      id={id}
                      user={user}
                      permissions={['edit', 'invite']}
                      sendInvitation={sendInvitationHub}
                    />
                  ))}
                </ul>
              )}
            {emails.length > 0 && (
              <Invite isNotEnoughSeats={isNotEnoughSeats} invite={invite} />
            )}
            <Description
              membeSeatsAvailable={inviteesQuery.data?.member_seats_available}
              numberGuests={emails.length}
            />
            <ExtraSeats />
            <Divider className={styles.divider} type="short-line" />
            <Title
              title="Already invited"
              description="Those invited will have permissions to all items nested in this space"
            />
            {inviteesQuery.data ? (
              <Invitees invitees={inviteesQuery.data} remove={remove} />
            ) : null}
          </div>
        </SideSheet.ContentContainer>
      </SideSheet.Body>
    </SideSheet>
  );
};
