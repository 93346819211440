import { ComponentProps, isValidElement, ReactNode } from 'react';

import { useHistory } from 'react-router';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import styles from './styles.module.css';

const GlobalError = ({
  text,
  icon,
  button,
}: {
  text: string;
  icon: ComponentProps<typeof Icon>['name'] | ReactNode;
  button?: ReactNode;
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        {isValidElement(icon) ? (
          icon
        ) : (
          <Icon size={80} name={icon as ComponentProps<typeof Icon>['name']} />
        )}
        <TypographyPoppins type="body" size="S" className={styles.text}>
          {text}
        </TypographyPoppins>
      </div>
      {button}
    </div>
  );
};

const GlobalErrorHomeButton = () => {
  const history = useHistory();

  const goHome = () => history.push('/home');

  return <Button label="Go to home" onClick={goHome} type="outlined" />;
};

const GlobalErrorGetHelpButton = ({ onClick }: { onClick?: () => void }) => {
  const openContactUs = () =>
    (window.location.href = 'https://www.visualistapp.com/contact');

  return (
    <Button
      label="Get help"
      onClick={() => {
        openContactUs();
        onClick?.();
      }}
      type="outlined"
    />
  );
};

export { GlobalError, GlobalErrorHomeButton, GlobalErrorGetHelpButton };
