import React from 'react';

import { motion } from 'framer-motion';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import { Comment as CommentType } from '@api/stickies';
import { Spinner } from '@components/Spinner';
import { ActionContainer } from '@pages/FileCard/Stickies/ActionContainer';
import { usePrevious } from '@src/shared/hooks/usePrevious';

import { ReplyDropdown } from '../ReplyDropdown';
import { ReplyFooter } from '../ReplyFooter';
import { StickyDropDown } from '../StickyDropDown';
import { StickyFooter } from '../StickyFooter';
import { StickyHeader } from '../StickyHeader';
import { StickyType } from '../types';
import { useStickyAction } from '../useStickyAction';
import { useStickyReplyAction } from '../useStickyReplyAction';

import styles from './styles.module.css';

type Props = {
  sticky: StickyType;
  replies?: CommentType[];
  loadingReplies: boolean;
  blockId: string;
};

export const StickyCardWithReplies = ({
  sticky,
  replies,
  loadingReplies,
  blockId,
}: Props) => {
  const lastComments = usePrevious(replies);
  const bottomElement = React.useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const { deleteStickyAction, createStickyAction, updateStickyAction } =
    useStickyAction({ imageId: blockId, actionId: sticky.action_sticky?.id });

  const { action_sticky } = sticky;
  const isActionable = !!action_sticky && !action_sticky.is_removed;

  React.useEffect(() => {
    // Scroll to the bottom of the comment section when new comments are added. Delay added for animation purposes.
    if (lastComments?.length === replies?.length) return;

    let timeout: NodeJS.Timeout;
    if (bottomElement.current) {
      timeout = setTimeout(() => {
        if (bottomElement.current)
          bottomElement.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [lastComments]);

  const toggleActionable = () => {
    if (isActionable) {
      deleteStickyAction.mutate(action_sticky.id);
    } else {
      createStickyAction.mutate({
        stickyId: sticky.id,
        status: 'open',
      });
    }
  };

  return (
    <div className={styles.container}>
      <div
        onContextMenu={(e) => {
          e.preventDefault();
          setIsDropdownOpen((v) => !v);
        }}
      >
        <ActionContainer
          isEditing={true}
          action={sticky.action_sticky}
          updateAction={updateStickyAction}
        />
        <div
          className={styles.stickyContainer}
          style={{
            // Add a delay for the start of the animation and remove for the end
            transitionDelay: isActionable ? '0.4s' : '0s',
          }}
        >
          <div
            style={{
              backgroundColor: sticky.background_hex,
            }}
            className={styles.innerContainer}
          >
            <StickyHeader
              expanded
              createdBy={sticky.created_by}
              createdAt={sticky.created_at}
              showActionableBadge={false}
            />
            <div className={styles.content}>
              <TypographyPoppins type="body" size="S" className={styles.text}>
                {sticky.text}
              </TypographyPoppins>
            </div>
            <StickyFooter
              isSelected
              numberOfReplies={sticky.comment_count}
              setShowDropdown={setIsDropdownOpen}
            />
            <StickyDropDown
              isActionable={isActionable}
              toggleActionable={toggleActionable}
              sticky={sticky}
              blockId={blockId}
              showDropdown={isDropdownOpen}
              setShowDropdown={setIsDropdownOpen}
              type="thread"
            />
          </div>
        </div>
      </div>
      {loadingReplies ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 16,
          }}
        >
          <Spinner />
        </div>
      ) : null}
      {replies?.map((reply, i) => (
        <Reply key={reply.id} reply={reply} index={i} stickyId={sticky.id} />
      ))}
      <div ref={bottomElement} id="scroll-comments" />
    </div>
  );
};

const ANIMATION_START_DELAY = 0.1;

const Reply = ({
  reply,
  index,
  stickyId,
}: {
  reply: CommentType;
  index: number;
  stickyId: string;
}) => {
  const {
    createStickyReplyAction,
    deleteStickyReplyAction,
    updateStickyReplyAction,
  } = useStickyReplyAction(stickyId, reply.action_sticky?.id);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const { action_sticky } = reply;
  const isActionable = !!action_sticky && !action_sticky.is_removed;

  const toggleActionable = () => {
    if (isActionable) {
      deleteStickyReplyAction.mutate(action_sticky.id);
    } else {
      createStickyReplyAction.mutate({
        stickyId: reply.id,
        status: 'open',
        isReply: true,
      });
    }
  };

  return (
    <motion.div
      style={{
        backgroundColor: 'var(--color-neutral-99)',
      }}
      initial={{
        opacity: 0,
        scale: 0.8,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
      transition={{
        delay: ANIMATION_START_DELAY + 0.05 * index,
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        setIsDropdownOpen((p) => !p);
      }}
    >
      <div>
        <ActionContainer
          isEditing={true}
          action={action_sticky}
          updateAction={updateStickyReplyAction}
        />
        <div className={styles.commentContainer}>
          <StickyHeader
            expanded
            createdBy={reply.created_by}
            createdAt={reply.created_at}
          />
          <div className={styles.content}>
            <TypographyPoppins type="body" size="S" className={styles.text}>
              {reply.text}
            </TypographyPoppins>
          </div>
          <ReplyFooter setIsDropdownOpen={setIsDropdownOpen} />
          <ReplyDropdown
            replyId={reply.id}
            showDropdown={isDropdownOpen}
            setShowDropdown={setIsDropdownOpen}
            isActionable={isActionable}
            toggleActionable={toggleActionable}
          />
        </div>
      </div>
    </motion.div>
  );
};
