import { useChangePermissions } from '@pages/BoardPage/model/queries/useChangePermissions';

import { InvitedUser } from '../user/invited-user';
import { Owner } from '../user/owner';

import styles from './styles.module.css';

type InviteesProps = {
  invitees: any;
  remove: (member: any) => void;
};

export const Invitees = ({ invitees, remove }: InviteesProps) => {
  const { chahgePermissions } = useChangePermissions({});

  return (
    <>
      <ul className={styles.guestList}>
        {invitees?.members?.map((invitee: any) => {
          if (invitee.is_owner) {
            return <Owner key={invitee.id} user={invitee} />;
          } else {
            return (
              <InvitedUser
                key={invitee.id}
                user={invitee}
                permissions={invitee.permissions}
                chahgePermissions={chahgePermissions}
                removeMember={remove}
              />
            );
          }
        })}
      </ul>
    </>
  );
};
