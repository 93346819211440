import { useEffect, useRef, useState } from 'react';

import { useUnit } from 'effector-react';
import { isMobile } from 'react-device-detect';

import {
  Button,
  Modal,
  TextField,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { useBoards } from '@pages/BoardPage/model/queries/useBoards';
import { Title } from '@src/entities/dialogs/ui/title';

import { DialogContainer } from '../../ui/dialog-container';
import {
  $boardDescription,
  $boardId,
  $boardName,
  $isShowBoardRenamingDialog,
  boardDescriptionCleared,
  boardDescriptionEntered,
  boardNameCleared,
  boardNameEntered,
  boardRenamingDialogClosed,
} from '../model';

import styles from './styles.module.css';

const MAX_LENGTH = 150;

export const BoardRenamingDialog = () => {
  const [previousName, setPreviousName] = useState('');
  const [previousDescription, setPreviousDescription] = useState('');

  const isShowBoardRenamingDialog = useUnit($isShowBoardRenamingDialog);
  const boardId = useUnit($boardId);
  const boardName = useUnit($boardName);
  const boardDescription = useUnit($boardDescription);

  const textField = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setPreviousName(boardName);
    setPreviousDescription(boardDescription);
  }, [isShowBoardRenamingDialog]);

  const { editBoard } = useBoards({});

  const updateBoard = () => {
    if (
      boardId &&
      boardName.trim().length > 0 &&
      boardName.trim().length <= MAX_LENGTH &&
      (boardName.trim() !== previousName ||
        boardDescription.trim() !== previousDescription)
    ) {
      editBoard({
        boardId,
        name: boardName,
        description: boardDescription,
      });
    }
    boardRenamingDialogClosed();
  };

  useEffect(() => {
    // Focus password element after animation is complete otherwise is janks
    let timeout: NodeJS.Timeout | null = null;

    timeout = setTimeout(() => {
      textField.current?.focus();
    }, 300);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isShowBoardRenamingDialog]);

  return (
    <Modal
      showModal={isShowBoardRenamingDialog}
      handleClose={boardRenamingDialogClosed}
    >
      <DialogContainer className={styles.board}>
        <Title className={styles.title} title="Update board" />
        <div className={styles.info}>
          <TypographyPoppins type="label" size="M" className={styles.label}>
            Board name
          </TypographyPoppins>
          <TextField
            ref={textField}
            value={boardName}
            onChange={(e) => boardNameEntered(e.target.value)}
            clear={() => boardNameCleared()}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                boardName.trim().length > 0 &&
                boardName.trim().length <= MAX_LENGTH
              ) {
                updateBoard();
              }
            }}
            tabIndex={1}
          />
        </div>
        <div className={styles.info}>
          <TypographyPoppins type="label" size="M" className={styles.label}>
            Description (optional)
          </TypographyPoppins>
          <div className={styles.textarea}>
            <textarea
              className={styles.description}
              value={boardDescription}
              onChange={(e) => boardDescriptionEntered(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey && !isMobile) {
                  e.preventDefault();
                  if (
                    boardName.trim().length > 0 &&
                    boardName.trim().length <= MAX_LENGTH
                  ) {
                    updateBoard();
                  }
                }
              }}
              tabIndex={2}
            />
            {boardDescription.trim() && (
              <button
                className={styles.clear}
                onClick={() => boardDescriptionCleared()}
                type="button"
              >
                <Icon name="sprite/x" size={16} />
              </button>
            )}
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            type="outlined"
            label="Cancel"
            onClick={() => boardRenamingDialogClosed()}
            tabIndex={4}
          />
          <Button
            type="filled"
            label="Done"
            onClick={updateBoard}
            isDisabled={
              boardName.trim().length === 0 ||
              boardName.trim().length > MAX_LENGTH
            }
            tabIndex={3}
          />
        </div>
      </DialogContainer>
    </Modal>
  );
};
