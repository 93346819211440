import { useState } from 'react';

import {
  Dropdown,
  IconButton,
  Item,
  TeamIcon,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { Icon } from '@visualist/icons';

import { BoardResponse } from '@api/boards';
import { BoardShare } from '@pages/BoardPage';
import { boardIdPassed } from '@pages/BoardPage/model';
import { deleteBoardModalOpened } from '@src/entities/delete-modals/modal';
import {
  boardDescriptionEntered,
  boardIdSelected,
  boardNameEntered,
  boardRenamingDialogOpened,
} from '@src/entities/dialogs/board/model';
import {
  boardIdForHubSelected,
  moveBoardDialogOpened,
} from '@src/entities/dialogs/board/move-board/model';
import {
  boardShareSheetOpened,
  idSelected,
} from '@src/entities/share-sheet/board/model/open-share-sheet';

import styles from './styles.module.css';

type Props = {
  board: BoardResponse;
  shareToWeb: (published: boolean, allow_comments?: boolean) => Promise<void>;
  boardShare: BoardShare | null;
};

export const Title = ({ board }: Props) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${import.meta.env.VITE_BASE_URL}/b/${board.id}`,
      );

      startedSnack({
        label: 'Copied link to board',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link to board",
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const rename = () => {
    boardRenamingDialogOpened();
    boardNameEntered(board.name);
    boardIdSelected(board.id);

    if (board.description) {
      boardDescriptionEntered(board.description);
    }
  };

  const deleteBoard = () => {
    deleteBoardModalOpened({
      boardId: board.id,
      parentBoardId: board.parent?.id,
      hubId: board.hub?.id,
    });
    boardIdPassed(board.id);
  };

  const menuItems: Item<Variant>[] = [
    {
      leadingIcon: <Icon name="sprite/link" size={21} />,
      content: 'Copy link',
      onClick: () => copyLink(),
    },
    {
      leadingIcon: <Icon name="sprite/pen" />,
      content: 'Rename',
      onClick: rename,
      isDivider: !board.hub && !board.top_parent?.parent && board.level !== 0,
    },

    ...((board.hub && board.level === 0) ||
    board.top_parent?.parent?.type === 'hub'
      ? [
          {
            leadingIcon: <TeamIcon />,
            content: 'Invite',
            onClick: () => {
              boardShareSheetOpened();
              idSelected(board.id);
            },
            isDivider: true,
          },
        ]
      : []),

    ...(!board.hub && board.level === 0
      ? [
          {
            leadingIcon: <Icon name="sprite/hub" />,
            content: 'Move into hub',
            onClick: () => {
              moveBoardDialogOpened();
              boardIdForHubSelected(board.id);
            },
            isDivider: true,
          },
        ]
      : []),

    {
      leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
      content: 'Delete',
      onClick: () => deleteBoard(),
      classNameContent: styles.delete,
    },
  ];

  return (
    <div className={styles.title}>
      <TypographyPoppins type="display" size="M" className={styles.name}>
        {board.name}
      </TypographyPoppins>
      <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
        <Dropdown.Menu
          trigger={
            <IconButton
              type="unfilled"
              icon={<Icon name="sprite/caret-down" />}
              isSelected={isOpenDropdown}
              onClick={() => {}}
            />
          }
          side="bottom"
          align="end"
          density="-2"
        >
          {menuItems.map((item, index) => (
            <Dropdown.MenuItem key={index} item={item} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
